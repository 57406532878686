import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum PizzaSide {
  WHOLE = 'WHOLE',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

@Component({
  selector: 'pizza-side-selector',
  templateUrl: './pizza-side-selector.component.html',
  styleUrls: ['./pizza-side-selector.component.scss'],
})
export class PizzaSideSelectorComponent {
  PizzaSide = PizzaSide;

  @Input() id: string = Math.random().toString(36).substr(2, 9);
  @Input() selected: PizzaSide = PizzaSide.WHOLE;
  @Output() onChangeSide = new EventEmitter();
}
