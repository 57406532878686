import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CompanyService } from '@app/@core/services/company.service';
import { notNull } from '@app/@shared/util-functions';
import { of } from 'rxjs';
import { catchError, filter, first, map, switchMap, take } from 'rxjs/operators';

export const ADMIN_LOGIN_STORAGE_KEY = 'ADMIN_LOGIN_STORAGE_KEY';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthenticationGuard implements CanActivate {
  constructor(private companyService: CompanyService, private router: Router, private http: HttpClient) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const urlTree = this.router.createUrlTree(['/admin-login'], {
      queryParams: { redirect: window.location.pathname },
    });
    const token = route.queryParams.token ?? sessionStorage.getItem(ADMIN_LOGIN_STORAGE_KEY);
    if (token) {
      return this.companyService.currentCompany$.pipe(
        filter(notNull),
        first(),
        switchMap(() => {
          return this.http.post<{ valid: boolean }>('loginjwt/validate', { token }).pipe(
            map(({ valid }) => {
              if (valid) {
                sessionStorage.setItem(ADMIN_LOGIN_STORAGE_KEY, token);
                return true;
              } else {
                return urlTree;
              }
            }),
            catchError(() => {
              return of(urlTree);
            })
          );
        })
      );
    } else {
      return urlTree;
    }
  }
}
