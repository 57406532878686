import { Component, OnInit, OnDestroy, NgZone, HostListener } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  ResolveStart,
  ResolveEnd,
  RouterEvent,
  Event,
  GuardsCheckStart,
  GuardsCheckEnd,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, merge, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, untilDestroyed } from '@core';
import { I18nService } from '@app/i18n';
import { MaestroService } from './@core/services/maestro.service';
import { ThemeService } from './@core/theme/service/theme.service';
import { CompanyService } from './@core/services/company.service';
import { notNull } from './@shared/util-functions';
import { sendEvent } from 'src/argus/argus';
import { AnalyticsEventType, EventType } from 'src/argus/types';
import { OrderService } from './@core/services/order.service';
import { AuthService } from './@core/services/auth.service';
import { EventService } from './@core/services/event.service';
import { EventTypeEnum } from './@core/models/events/EventTypeEnum';
import { SessionService } from './@core/services/session.service';
import { ToastrService } from 'ngx-toastr';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();

  private _redirecting = new BehaviorSubject(false);
  public redirecting$ = this._redirecting.asObservable();

  private _loadingRoute = new BehaviorSubject(false);
  public loadingRoute$ = this._loadingRoute.asObservable();

  private _loadingTheme = new BehaviorSubject(false);
  public loadingTheme$ = this._loadingTheme.asObservable();

  private _loadingSomething = new BehaviorSubject(false);
  public loadingSomething$ = this._loadingSomething.asObservable();

  private _fillingReorder = new BehaviorSubject(false);
  public fillingReorder$ = this._fillingReorder.asObservable();

  private _openingViewOrder = new BehaviorSubject(false);
  public openingViewOrder$ = this._openingViewOrder.asObservable();

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    log.info('Closing page, start to end the current session');
    this.sessionService.ClearSession();
    this.traceEventService.SaveEvent(
      this.companyService?.currentCompany?.company_id as string,
      EventTypeEnum.SESSION_END,
      'The session has ended'
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private maestroService: MaestroService,
    public authService: AuthService,
    public companyService: CompanyService,
    public orderService: OrderService,
    public themeService: ThemeService,
    private traceEventService: EventService,
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
    // Keybinds and commands to toggle cache
    this.listenToToggleCacheCommands();
    this.subs.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.disableCache || params.enableCache) {
          if (params.disableCache) {
            localStorage.setItem('cache', 'false');
          } else if (params.enableCache) {
            localStorage.setItem('cache', 'true');
          }
          this.showToastrToggleCache();
        }
      })
    );
    // Remove main loader if things take too long
    setTimeout(() => {
      this._redirecting.next(false);
      this._loadingRoute.next(false);
      this._loadingTheme.next(false);
    }, 15 * 1000);
    this.loadingSomething$ = combineLatest([this.redirecting$, this.loadingRoute$, this.loadingTheme$]).pipe(
      map((obs) => {
        return obs.some((ob) => ob === true);
      })
    );
    this.subs.add(
      this.router.events.subscribe((routerEvent: Event) => {
        if (routerEvent instanceof ResolveStart || routerEvent instanceof GuardsCheckStart) {
          this._loadingRoute.next(true);
        }

        if (routerEvent instanceof ResolveEnd || routerEvent instanceof GuardsCheckEnd) {
          this._loadingRoute.next(false);
        }

        if (routerEvent instanceof NavigationEnd) {
          if (environment.production) {
            sendEvent({
              type: EventType.analytics,
              analytics_type: AnalyticsEventType.PAGE_VIEW,
              message: routerEvent.urlAfterRedirects,
            });

            this.traceEventService.SaveEvent(
              companyService.currentCompany?.company_id as string,
              EventTypeEnum.PAGE_VIEW,
              'Page view',
              {
                route: routerEvent.urlAfterRedirects,
                url: routerEvent.url,
              }
            );
          }
        }
        if (location.pathname.includes('/company-groups')) this._loadingTheme.next(false);
      })
    );
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('auth_token');
      if (token) {
        this.authService.authenticate(token);
        params.delete('auth_token');
        window.location.search = params.toString();
      }
    }
  }

  public backToCustomize() {
    this.router.navigate(['/customize'], {
      queryParams: { id: this.themeService.previewing?.id },
    });
    this.themeService.previewing = null;
  }
  public applyTheme() {
    this.themeService.applyTheme();
  }

  async ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    log.info('Init');

    // Start Theme Service
    this.themeService.init();
    if (!location.pathname.includes('/company-groups')) this._loadingTheme.next(true);
    this.subs.add(
      this.themeService.theme$.pipe(filter(notNull)).subscribe(() => {
        this._loadingTheme.next(false);
      })
    );

    this.maestroService.init();

    // Reorder from a different company
    const urlSearchParams = new URLSearchParams(window.location.search);
    const reorderId = urlSearchParams.get('reorderId');
    if (reorderId) {
      if (!this.authService.isAuthenticated()) {
        localStorage.setItem('reorderId', reorderId);
        this.companyService.currentCompany$.pipe(filter(notNull)).subscribe(() => {
          this.authService.openLogin();
        });
      }
    }

    // Open View Order from external
    const viewOrderId = urlSearchParams.get('viewOrderId');
    if (viewOrderId) {
      this._openingViewOrder.next(true);
      const deptId = urlSearchParams.get('deptId');
      this.companyService.storeList$.pipe(filter(notNull)).subscribe((storeList) => {
        const store = storeList.find((store) => store.id === deptId);
        window.location.href = window.location.origin + `/${store?.url_path.toLowerCase()}/view-order/${viewOrderId}`;
      });
      this.subs.add(
        this.orderService.orderList$.pipe(filter(notNull)).subscribe(() => {
          this._openingViewOrder.next(false);
        })
      );
    }

    // Show loading while filling reorder
    if (localStorage.getItem('reorderId')) {
      this._fillingReorder.next(true);
      this.subs.add(
        this.orderService.currentOrder$.pipe(filter(notNull)).subscribe(() => {
          this._fillingReorder.next(false);
        })
      );
    }

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  private isCacheEnabled() {
    const useCacheLocalSetting = localStorage.getItem('cache');
    return !useCacheLocalSetting || (useCacheLocalSetting && useCacheLocalSetting === 'true');
  }

  private listenToToggleCacheCommands() {
    window.addEventListener('keydown', (event) => {
      if (event.defaultPrevented) {
        return;
      }

      if (event.ctrlKey && event.shiftKey && event.code === 'KeyC') {
        if (this.isCacheEnabled()) {
          localStorage.setItem('cache', 'false');
        } else {
          localStorage.setItem('cache', 'true');
        }
        this.showToastrToggleCache();
      }
    });
  }

  private showToastrToggleCache() {
    this.toastr.info('Refreshing...', `Cache ${this.isCacheEnabled() ? 'enabled!' : 'disabled!'}`);
    setTimeout(() => {
      window.location.search = '';
    }, 1000);
  }

  ngOnDestroy() {
    this.i18nService.destroy();
    this.subs.unsubscribe();
  }
}
