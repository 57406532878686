import { PizzaSide } from '@app/screens/item-drawer/components/pizza-side-selector.component';
import { ILocationModifiers } from '../modifier-group/location-modifiers.model';
import { IModifierGroup } from '../modifier-group/modifier-group.model';
import { IModifierGroupsOverrides } from '../modifier-group/modifier-groups-overrides.model';
import { IPizzaTopping } from '../pizza-topping.model';
import { IPrepTime } from '../prep-time.model';
import { ISchedule } from '../schedule.model';

export interface IItem {
  id: any;
  guid: any;
  type: string;
  name: string;
  label: string;
  description: string;
  group_id: string;
  menu_id: string;
  company_id: string;
  store_id: number;
  parent_id: string;
  isCombo: boolean;
  is_taxable: boolean;
  is_disabled: boolean;
  isActive: boolean;
  contains_alcohol: boolean;
  calories: number;
  price: number;
  tax: number;
  prediscount_price: number;
  pos_price: number;
  price_max: number;
  price_min: number;
  system_price: number;
  availabilities: string[];
  included: string[];
  excluded: IItem[];
  disabled_modifier_groups: string[];
  modifier_groups: string[];
  sort: number;
  menu_item_type: number;
  notes: string;
  pos_description: string;
  pos_is_active: boolean;
  pos_name: string;
  tax_rate: number;
  properties: [];
  tags: any[];
  images: any[];
  prepTime?: IPrepTime;
  schedule: ISchedule;
  parent_item_id: string;
  item_id: string;
  image: string;
  quantity: number;
  quantity_label: boolean;
  modifiers: IItem[];
  min: number;
  max: number;
  location_modifiers: ILocationModifiers;
  modifier_groups_overrides: IModifierGroupsOverrides;
  is_pizza_topping: boolean;
  pizza_topping: IPizzaTopping;
  pizza_side: PizzaSide;
  preselected: boolean;
  modifier_weight: number;
}

export class Item implements IItem {
  public id: any;
  public guid: any;
  public type: string;
  public name: string;
  public label: string;
  public description: string;
  public menu_id: string;
  public group_id: string;
  public company_id: string;
  public store_id: number;
  public parent_id: string;
  public isCombo: boolean;
  public is_taxable: boolean;
  public is_disabled: boolean;
  public isActive: boolean;
  public contains_alcohol: boolean;
  public calories: number;
  public price: number;
  public prediscount_price: number;
  public tax: number;
  public pos_price: number;
  public price_max: number;
  public price_min: number;
  public system_price: number;
  public availabilities: string[];
  public included: string[];
  public excluded: IItem[];
  public disabled_modifier_groups: string[];
  public modifier_groups: string[];
  public sort: number;
  public menu_item_type: number;
  public notes: string;
  public pos_description: string;
  public pos_is_active: boolean;
  public pos_name: string;
  public tax_rate: number;
  public properties: [];
  public tags: any[];
  public images: any[];
  public prepTime?: IPrepTime;
  public schedule: ISchedule;
  public parent_item_id: string;
  public item_id: string;
  public image: string;
  public quantity: number;
  public quantity_label: boolean;
  public modifiers: IItem[];
  public min: number;
  public max: number;
  public location_modifiers: ILocationModifiers;
  public modifier_groups_overrides: IModifierGroupsOverrides;
  public is_pizza_topping: boolean;
  public pizza_topping: IPizzaTopping;
  public pizza_side: PizzaSide;
  public preselected: boolean;
  public modifier_weight: number;

  constructor(obj: IItem) {
    Object.entries(obj).forEach(([key, value]) => Object.assign(this, { [key]: value }));
  }
}
