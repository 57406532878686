import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';

export interface MenuThemeConfig {
  menu: {
    template: 'default' | 'olo';
    headerSloganColor: string;
    headerBackgroundImage: string;
  };
}
export const defaultMenuThemeConfig: MenuThemeConfig = {
  menu: {
    headerSloganColor: '#ffffff',
    headerBackgroundImage: '',
    template: 'default',
  },
};
export const menuC11nMetadata: CustomizableGroup = {
  title: 'Menu',
  name: 'menu',
  items: [
    {
      title: 'Background Image (Header)',
      name: 'headerBackgroundImage',
      type: CustomizableItemType.IMAGE,
    },
    {
      title: 'Slogan Color (Header)',
      name: 'headerSloganColor',
      type: CustomizableItemType.COLOR,
      cssVarName: 'menuHeaderSloganColor',
    },
    {
      title: 'Template',
      name: 'template',
      type: CustomizableItemType.SELECT,
      options: [
        {
          label: 'Default',
          value: 'default',
        },
        {
          label: 'Olo',
          value: 'olo',
        },
      ],
    },
  ],
};
