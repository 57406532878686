import { CSS } from '@app/@core/theme/model/theme-config';
import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';

export interface ItemCardThemeConfig {
  itemCard: {
    template: 'with-pictures' | 'with-some-pictures' | 'without-pictures';
    css?: CSS;
  };
}
export const defaultItemCardThemeConfig: ItemCardThemeConfig = {
  itemCard: {
    template: 'with-some-pictures',
  },
};
export const itemCardC11nMetadata: CustomizableGroup = {
  title: 'Menu Item Card',
  name: 'itemCard',
  items: [
    {
      title: 'Template',
      name: 'template',
      type: CustomizableItemType.SELECT,
      options: [
        {
          label: 'With Some Pictures',
          value: 'with-some-pictures',
        },
        {
          label: 'With Pictures',
          value: 'with-pictures',
        },
        {
          label: 'Without Pictures',
          value: 'without-pictures',
        },
      ],
    },
  ],
};

export interface MenuTag {
  SystemData: {
    CompanyKey: string;
    DeptID: number;
    EventLog?: [];
    Guid: string;
    ObjectType: string;
    Partition: string;
    Path: string;
    Provider: string;
    RefID: string;
  };
  entity_type: string;
  guid: string;
  id: string;
  is_disabled: false;
  last_modified: string;
  name: string;
  sort: number;
  system_default: false;
  system_id: number;
  url_icon: string;
}
