<div class="PizzaSideSelector">
  <input
    [id]="id + '-left'"
    type="radio"
    [name]="id + 'pizza-side-selector'"
    [checked]="selected === PizzaSide.LEFT"
    (change)="onChangeSide.emit(PizzaSide.LEFT)"
  />
  <label [for]="id + '-left'" title="Left side">
    <span class="side left-side"></span>
  </label>

  <input
    [id]="id + '-whole'"
    type="radio"
    [name]="id + 'pizza-side-selector'"
    [checked]="selected === PizzaSide.WHOLE"
    (change)="onChangeSide.emit(PizzaSide.WHOLE)"
  />
  <label [for]="id + '-whole'" title="Both sides">
    <span class="side whole-side"></span>
  </label>

  <input
    [id]="id + '-right'"
    type="radio"
    [name]="id + 'pizza-side-selector'"
    [checked]="selected === PizzaSide.RIGHT"
    (change)="onChangeSide.emit(PizzaSide.RIGHT)"
  />
  <label [for]="id + '-right'" title="Right side">
    <span class="side right-side"></span>
  </label>
  <p class="description">
    {{ selected === PizzaSide.WHOLE ? 'Both sides' : selected === PizzaSide.LEFT ? 'Left side' : 'Right side' }}
  </p>
</div>
