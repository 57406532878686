import { CSS } from '@app/@core/theme/model/theme-config';

export enum CustomizableItemType {
  COLOR = 'COLOR',
  CSS = 'CSS',
  FONT = 'FONT',
  SELECT = 'SELECT',
  GROUP = 'GROUP',
  IMAGE = 'IMAGE',
}

export interface CustomizationObject {
  general?: {
    primary: string;
    secondary: string;
    white: string;
    black: string;
    dark: string;
    light: string;
  };
  text?: {
    color: string;
    colorInverse: string;
    fontFamily: string;
  };
  tab?: {
    containerBackgroundColor: string;
    activeItemBackgroundColor: string;
    inactiveItemBackgroundColor: string;
    cssActive: string;
    cssInactive: string;
  };
  storeCard?: {
    template: 'with-header' | 'button-at-right' | 'button-at-left';
  };
  storePicker?: {
    template?: 'map-left' | 'map-right' | 'without-map';
    tab?: {
      containerBackgroundColor: string;
      activeItemBackgroundColor: string;
      inactiveItemBackgroundColor: string;
      cssActive: string;
      cssInactive: string;
    };
  };
  menu?: {
    template: 'without-pictures' | 'map-left' | 'map-right';
  };
  button?: {
    backgroundColor: string;
    textColor: string;
    css?: {
      hover: string;
    };
  };
  itemCard: {
    template: 'with-pictures' | 'with-some-pictures' | 'without-pictures';
  };
}

export interface BaseCustomizableItem {
  name: string;
  title: string;
  type: CustomizableItemType;
}

export interface CustomizableImage extends BaseCustomizableItem {
  type: CustomizableItemType.IMAGE;
}

export interface CustomizableColor extends BaseCustomizableItem {
  type: CustomizableItemType.COLOR;
  cssVarName: string;
}

export interface CustomizableCSS extends BaseCustomizableItem {
  type: CustomizableItemType.CSS;
}

export interface CustomizableFont extends BaseCustomizableItem {
  type: CustomizableItemType.FONT;
}

export interface CustomizableSelect extends BaseCustomizableItem {
  type: CustomizableItemType.SELECT;
  options: string[] | { label: string; value: string }[];
}

export interface CustomizableInnerGroup extends BaseCustomizableItem, CustomizableGroup {
  type: CustomizableItemType.GROUP;
}

export type CustomizableItem =
  | CustomizableColor
  | CustomizableCSS
  | CustomizableFont
  | CustomizableSelect
  | CustomizableImage
  | CustomizableInnerGroup;

export interface CustomizableGroup {
  name: string;
  title: string;
  items: CustomizableItem[];
  path?: string[];
}

export interface CustomizableValue {
  [groupName: string]: {
    [itemName: string]: string | CSS | CustomizableValue;
  };
}

export type CustomizableValueOptions = {
  customHover?: boolean;
  type?: 'base' | 'hover';
};
