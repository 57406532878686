// tslint:disable: member-ordering
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ControledByMaestro } from './maestro.service';
import { Logger } from './logger.service';
import { Customer, ICustomer } from '@app/@core/models/customer/customer.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ResponseObj } from '../models/response.model';

const logger = new Logger('CustomerService');

@Injectable({
  providedIn: 'root',
})
export class CustomerService implements ControledByMaestro, OnDestroy {
  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) {}

  private readonly _customer = new BehaviorSubject<Customer | null>(null);

  private subs: Subscription = new Subscription();

  public readonly customer$ = this._customer.asObservable();

  public get customer() {
    return this._customer.getValue();
  }

  public init() {
    logger.info('Init');
    this.subs.add(
      this.getCustomer().subscribe((customer) => {
        if (customer) {
          logger.info('Customer fetched');
          this._customer.next(customer);
        }
      })
    );
  }

  public saveCustomer(customer: Customer) {
    const request = this.http
      .put('Customer', customer)
      .pipe(
        switchMap(() => this.getCustomer()),
        map((customer) => this._customer.next(customer))
      )
      .toPromise();
    return request;
  }

  private getCustomer() {
    return this.http.get<ResponseObj<ICustomer>>('Customer').pipe(
      map((response) => new Customer(response.Data)),
      catchError((err) => {
        this.toastr.error('We had a problem fetching customer data, try it again later.', 'Oops!');
        logger.error('Error fetching Customer!', err);
        this.router.navigate([{ outlets: { modal: null } }]);
        return of(null);
      })
    );
  }

  saveGuestEmailForPromotions() {
    let preferences = {
      newsletter: true,
    };
    this.http.put<Customer>('', preferences).subscribe({
      next: (data) => {
        return data;
      },
      error: (error) => {
        this.toastr.error('Unable to update user preferences. Try again later.');
        return error;
      },
    });
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
