import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { fromUTC } from '../util-functions';

@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
  transform(utcDate?: string, displayTzOffset?: number): moment.Moment {
    return fromUTC(displayTzOffset, utcDate);
  }
}
