import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { createUUID } from 'src/argus/utils';
import { Session } from '../models/session/session.model';
import { Logger } from './logger.service';

const log = new Logger('SessionService');

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly _session = new BehaviorSubject<Session | null>(null);

  private subs: Subscription = new Subscription();

  public readonly session$ = this._session.asObservable();

  public get Session(): Session | null {
    var val = this._session.getValue();
    if (!val) {
      var currentSession = localStorage.getItem('CURRENT_SESSION');
      if (currentSession) {
        return JSON.parse(currentSession) as Session;
      }

      return null;
      // var newId = createUUID();
      // log.info('The session is null , the new id for the session is ' + newId);
      // var newSession = {
      //     id: newId,
      //     startTimestamp: new Date(),
      // } as Session;
      // localStorage.setItem('CURRENT_SESSION', JSON.stringify(newSession));
      // this._session.next(newSession);
    } else {
      log.info('there is already a session opened for this user, the id is' + val.SessionId);
    }
    return this._session.getValue() as Session;
  }

  public set Session(newSession) {
    localStorage.setItem('CURRENT_SESSION', JSON.stringify(newSession));
    this._session.next(newSession);
  }

  public ClearSession() {
    this._session.next(null);
  }

  /**
   *
   */
  constructor() {}
}
