import { Component, OnDestroy } from '@angular/core';
import { CompanyService } from '@app/@core/services/company.service';
import { notNull } from '@app/@shared/util-functions';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cookies-popup',
  templateUrl: './cookies-popup.component.html',
  styleUrls: ['./cookies-popup.component.scss'],
})
export class CookiesPopupComponent implements OnDestroy {
  private subs: Subscription = new Subscription();
  public showPopup: boolean;

  constructor(private companyService: CompanyService) {
    this.companyService.currentCompany$.pipe(filter(notNull)).subscribe(() => {
      const alreadyAccepted = localStorage.getItem('COOKIES-CONSENT');
      const isExpired = parseInt(localStorage.getItem('COOKIES-CONSENT-EXPIRE') ?? '0', 10) <= new Date().getTime();
      if (alreadyAccepted === 'true' && !isExpired) {
        this.showPopup = false;
      } else {
        this.showPopup = true;
      }
    });
  }

  public closePopup(accept: boolean) {
    localStorage.setItem('COOKIES-CONSENT', accept ? 'true' : 'false');
    if (accept) {
      // One year
      localStorage.setItem('COOKIES-CONSENT-EXPIRE', (new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toString());
    }
    this.showPopup = false;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
