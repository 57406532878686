// tslint:disable: member-ordering
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Logger } from './logger.service';
import { ControledByMaestro } from './maestro.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseObj, Response } from '@app/@core/models/response.model';
import * as moment from 'moment-timezone';
import { Group } from '@app/@core/models/menu/group.model';
import { fromUTC, getPeriods, getSubdomain, isDateOnSchedule, notNull, toUTC } from '@app/@shared/util-functions';
import { environment } from '@env/environment';
import { Company, ICompany, NewCompany } from '../models/company/company.model';
import {
  CompanyGroup,
  SystemCompanyGroup,
} from '@app/screens/company-group-store-picker/company-group-store-picker.model';
import { MenuData, MenuResponse } from '../models/menu/menu.model';
import { Store } from '../models/store/store.model';
import { IOrder, Order, OrderType } from '../models/order/order.model';
import { ISchedule, Schedule } from '../models/schedule.model';
import { IScheduleDay } from '../models/schedule_day.model';
import { PrepTime } from '../models/prep-time.model';
import { StoreUtils, StoreUtilsTypes } from '../models/company/store-utils.model';
import { Item } from '../models/item/item.model';
import { Weekday } from '../models/weekday.model';

export const STAGING_ENV_SUBDOMAIN = 'staging';

const logger = new Logger('CompanyService');

@Injectable({
  providedIn: 'root',
})
export class CompanyService implements ControledByMaestro, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  private readonly _companyGroup = new BehaviorSubject<CompanyGroup | null>(null);
  private readonly _companyGroupList = new BehaviorSubject<SystemCompanyGroup[] | null>(null);
  private readonly _companyList = new BehaviorSubject<Company[] | null>(null);
  private readonly _storeList = new BehaviorSubject<Store[] | null>(null);
  private readonly _currentCompany = new BehaviorSubject<Company | null>(null);
  private readonly _currentStore = new BehaviorSubject<Store | null>(null);
  private readonly _currentMenu = new BehaviorSubject<MenuData | null>(null);

  private subs: Subscription = new Subscription();

  public readonly companyGroup$ = this._companyGroup.asObservable();
  public readonly companyGroupList$ = this._companyGroupList.asObservable();
  public readonly companyList$ = this._companyList.asObservable();
  public readonly currentCompany$ = this._currentCompany.asObservable();
  public readonly storeList$ = this._storeList.asObservable();
  public readonly currentStore$ = this._currentStore.asObservable();
  public readonly currentMenu$ = this._currentMenu.asObservable();
  public readonly currentStoreUtils$: Observable<StoreUtils> = this._currentStore.pipe(
    filter(notNull),
    map(this.getStoreUtils.bind(this))
  );

  public get companyGroup() {
    return this._companyGroup.getValue();
  }

  public get companyGroupList() {
    return this._companyGroupList.getValue();
  }

  public get companyList() {
    return this._companyList.getValue();
  }

  public get currentCompany() {
    return this._currentCompany.getValue();
  }

  public get storeList() {
    return this._storeList.getValue();
  }

  public get currentStore() {
    return this._currentStore.getValue();
  }

  public get currentMenu() {
    return this._currentMenu.getValue();
  }

  public get useCache() {
    // // TODO introduce a local storage angular service
    const useCacheLocalSetting = localStorage.getItem('cache');
    // the default
    let useCache = (environment.production && !environment.preRelease) || environment.version?.includes('-dev');
    if (useCacheLocalSetting) {
      useCache = useCacheLocalSetting === 'true' ? true : false;
    }
    // return useCache;
    return false;
  }

  public init() {
    logger.info('Init');
    this.syncCompanyAndStoreOnRouteChange();
    this.getCompanyListAndListenToChange();
    if (this.useCache === true) {
      // this.subs.add(this.listenToCachedMenuChange());
    }
  }

  public getOrderTimePlusTimeWindowMinutes(store: Store, order: Order): string | null {
    const info = this.getStoreUtils(store);
    const orderType = order.order_type;
    const timeWindowMinutes =
      info.getOrderOptions().find((o) => o.order_type === orderType)?.time_window_minutes ?? null;

    if (!timeWindowMinutes) {
      return null;
    }

    const utcDate = fromUTC(info.tzOffset, order.date_asked);
    const adjustedDate = moment(utcDate).add(timeWindowMinutes, 'minutes');
    const formattedMaxTime = adjustedDate.format('hh:mm A');
    return ` - ${formattedMaxTime}`;
  }

  private syncCompanyAndStoreOnRouteChange() {
    this.subs.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        if (event && event instanceof NavigationEnd && !event.url.startsWith('/#')) {
          logger.info('Route Changed, checking subdomain and store');
          this.checkSubdomain();
          this.checkStore();
        }
      })
    );
  }

  private getCompanyListAndListenToChange() {
    this.subs.add(
      forkJoin({ companies: this.getCompanies(), companyGroups: this.getCompanyGroups() }).subscribe(
        ({ companies, companyGroups }) => {
          logger.info('Companies fetched');
          this._companyList.next(companies);
          this._companyGroupList.next(companyGroups);
          this.checkSubdomain();
        }
      )
    );
  }

  public getStoreListAndListenToChange(company: Company) {
    this.subs.add(
      this.getStores(company).subscribe((stores) => {
        logger.info('Stores fetched');
        this._storeList.next(stores);
        this.checkStore();
      })
    );
    this.subs.add(
      this._currentStore.subscribe((store) => {
        if (store && store.url_path) {
          const storeId = store.id;
          if (storeId && !(this.currentMenu?.storeId === storeId)) {
            logger.info('Current Store changed, fetching menu');
            this.subs.add(
              this.getMenu(store)
                .pipe(map((menu) => ({ ...menu, storeId: store.id })))
                .subscribe((menu) => {
                  this._currentMenu.next(menu);
                })
            );
          }
        } else {
          logger.info('Current Store changed, going back to store picker');
        }
      })
    );
  }

  private checkCompanyGroupSubdomain() {
    const currentSubdomain = getSubdomain();
    const validSubdomains = this.companyGroupList?.map((companyGroup) => companyGroup.subdomain) || [];

    if (currentSubdomain && validSubdomains.includes(currentSubdomain)) {
      return {
        isCurrentSubdomainFromCompanyGroup: true as const,
        companyGroupId: this.companyGroupList?.find((cg) => cg.subdomain === currentSubdomain)?.id as string,
      };
    } else {
      return { isCurrentSubdomainFromCompanyGroup: false as const, companyGroupId: null };
    }
  }

  private checkSubdomain() {
    const { isCurrentSubdomainFromCompanyGroup, companyGroupId } = this.checkCompanyGroupSubdomain();
    if (isCurrentSubdomainFromCompanyGroup) {
      return this.router.navigate([`/company-groups/${companyGroupId}/stores`]).then(() => {
        this.getCompanyGroup(companyGroupId);
      });
    }

    const companiesLoaded = this.companyList !== null;
    const currentSubdomain = getSubdomain();
    const validSubdomains = this.companyList?.map((company) => company.subdomain) || [];
    if (currentSubdomain && validSubdomains?.includes(currentSubdomain)) {
      if (currentSubdomain !== this.currentCompany?.subdomain) {
        this._currentCompany.next(this.companyList?.find((company) => company.subdomain === currentSubdomain) ?? null);
      }
    } else {
      this._currentCompany.next(null);
      this._currentStore.next(null);
      this._currentMenu.next(null);

      if (currentSubdomain === STAGING_ENV_SUBDOMAIN) {
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
      }
      // Invalid subdomain
      else if (currentSubdomain && companiesLoaded) {
        this.router.navigate(['/company-not-found']);
      }
    }
  }

  private checkStore() {
    const storesLoaded = this.storeList !== null;
    // Traverse activated route
    let _route = this.route;
    while (_route.firstChild !== null) {
      _route = _route.firstChild;
    }
    const currentStore = (_route.params as BehaviorSubject<Params>).value.store;
    const validStoresUrl = this.storeList?.map((store) => store.url_path);
    if (currentStore && validStoresUrl?.includes(currentStore)) {
      if (currentStore !== this.currentStore?.url_path) {
        this._currentStore.next(this.storeList?.find((store) => store.url_path === currentStore) ?? null);
      }
    } else {
      this._currentStore.next(null);
      this._currentMenu.next(null);

      // Invalid store
      if (currentStore && storesLoaded) {
        this.router.navigate(['/']);
      }
    }
    this.setCustomCss();
  }

  private setCustomCss() {
    const store = this.currentStore || this.storeList?.[0];
    if (store) {
      const {
        background_color,
        background_imgs,
        // button_colors,
        fav_icon,
        header_background_color,
        // text_colors,
        tile_background_color,
        tile_background_opacity,
      } = store.cms_content;

      // Header Color
      document.documentElement.style.setProperty('--header-color', header_background_color);

      // Background Image and color
      document.documentElement.style.setProperty('--background-color', background_color);
      document.documentElement.style.setProperty('--background-img', `url(${background_imgs?.[0]})`);

      // @TODO We need to find a way to dynamically change sass variables
      // Buttons
      // Texts

      // Tile
      document.documentElement.style.setProperty('--tile-background-color', tile_background_color);
      document.documentElement.style.setProperty('--tile-background-opacity', tile_background_opacity);

      // Fav Icon
      const link: HTMLLinkElement = document.querySelector('link[rel*="icon"]') || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = fav_icon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }

  public companyGroupReq: Observable<CompanyGroup>;
  public getCompanyGroup(_groupId?: string) {
    if (!this.companyGroupReq) {
      const groupId = _groupId || this.currentCompany?.groupId;
      if (groupId) {
        this.companyGroupReq = this.http
          .get<ResponseObj<CompanyGroup>>(`CompanyGroup/${groupId}`)
          .pipe(map((response) => response.Data));
        if (!this.companyGroup)
          this.subs.add(this.companyGroupReq.subscribe((response) => this._companyGroup.next(response)));
      }
    }
    return this.companyGroupReq;
  }

  private getCompanies() {
    return this._getCompanies().pipe(
      map((response) =>
        response.Data.map((company) => ({
          ...company,
          subdomain: company.subdomain?.toLowerCase(),
          subdomain_orig: company.subdomain,
        }))
      ),
      catchError((err) => {
        this.toastr.error('We had a problem fetching companies, try it again later.', 'Oops!');
        logger.error('Error fetching companies!', err);
        this.router.navigate(['/']);
        return of([]);
      })
    );
  }

  public getStores(company: ICompany) {
    return this._getStores(company).pipe(
      map((response) =>
        response.Data.map((store) => ({
          ...store,
          url_path: store.url_path.toLowerCase(),
        }))
      )
    );
  }

  public formatMenuResponse(menuResponse: MenuResponse, storeId: string): MenuData {
    const menus = menuResponse.menus.filter(({ is_disabled }) => !is_disabled).sort((a, b) => a.sort - b.sort);
    const _groups = menus
      .reduce((groups, menu) => [...(menu.groups ?? []), ...groups], [] as Group[])
      .filter((g) => !g.web_inactive);
    if (_groups) {
      const items: Item[] = [];
      menuResponse.items
        .concat(...menuResponse.modifier_options)
        .filter((item) => !item.is_disabled)
        .forEach((item) => {
          items.push(new Item(item));
        });
      const validItems = items.map((item) => item.id);
      const categories = _groups.map((group) => ({ id: group.id as string, name: group.name as string }));
      const modifierGroups = menuResponse.modifier_groups.map((modGroup) => {
        modGroup.items = modGroup.items?.filter((itemId) => validItems.includes(itemId));
        return modGroup;
      });
      const groups = _groups.map((g) => {
        g.items = g.items
          ?.map((itemId) => items.find((item) => item.id === itemId))
          .filter((item) => item !== undefined)
          .map((item: any) => ({ ...item, group_id: g.id }));
        return g;
      });

      return {
        storeId,
        menus,
        categories,
        groups,
        items,
        modifierGroups,
        prepTimes: menus.filter((menu) => !!menu.prepTime).map((menu) => menu.prepTime as PrepTime),
      };
    } else {
      return {
        storeId,
        menus: [],
        categories: [],
        groups: [],
        items: [],
        modifierGroups: [],
        prepTimes: [],
      };
    }
  }

  private getMenu(store: Store): Observable<MenuData> {
    return this._getMenu(store).pipe(
      map((response) => response.Data),
      map((r) => this.formatMenuResponse(r, store.id))
    );
  }

  public getStoreUtils(store: Store) {
    const getOrderTypeSchedule = (orderType: OrderType): Schedule => {
      const orderOptionSchedule = store.order_options.find(
        (orderOption) => orderOption.order_type === orderType && orderOption.enabled && !orderOption.is_deleted
      );
      return (
        orderOptionSchedule?.schedule ?? {
          always_available: false,
          date_range: false,
          date_list: false,
          endDate: '',
          startDate: '',
          schedule_days: [],
          dates: [],
        }
      );
    };

    const getActiveDays = (orderType: OrderType) => {
      const schedule = getOrderTypeSchedule(orderType);
      return schedule?.schedule_days.filter((day) => day.is_active) || [];
    };

    const hasActiveDays = () => {
      return store.order_options
        .filter((op) => op.enabled && !op.is_deleted)
        .some((op) => getActiveDays(op.order_type).length > 0);
    };

    const getOrderOptions = () => store.order_options;
    const getReceiverField = () => store.receiver_field;
    const getIsDisabled = () => store.is_disabled || store.is_paused || hasActiveDays() === false;
    const getAllowPaymentOnDelivery = () => store.allow_payment_on_delivery;
    const getContactMail = () => store.contact_email;
    const getCustomizableFee = () => store.customizable_fee;

    const getWeekDay = (days: IScheduleDay[], dayName: string) => {
      return days.find((day) => day.day === dayName);
    };

    const getValidTimes = (dateInStoreTimeZone: moment.Moment, orderType: OrderType, currentOrder?: Order) => {
      const today = fromUTC(store.time_zone_offset_minutes, moment.utc());
      let date = dateInStoreTimeZone.clone();

      if (date.isBefore(today, 'day')) {
        date = today.clone();
      }

      let validTimes: string[] | null = [];
      let error: string | null = null;
      let holidayWarning: boolean = false;
      if (getIsDisabled()) {
        validTimes = null;
        error = 'Online Ordering Is Unavailable.';
      } else {
        const schedule = getOrderTypeSchedule(orderType);
        if (
          isDateOnSchedule(
            schedule,
            toUTC(store.time_zone_offset_minutes, date.clone()),
            store.time_zone_offset_minutes,
            true
          )
        ) {
          const getValidTimesForDay = (day: IScheduleDay, schedule: ISchedule) => {
            const gapTime = 15;
            const periods = getPeriods(schedule, day.day);
            const vt: string[] = [];

            periods.forEach((period) => {
              const firstTime = moment.utc(period.start, 'hh:mm A');
              const lastTime = moment.utc(period.end, 'hh:mm A');
              const currentTime = firstTime;

              while (currentTime.isBefore(lastTime)) {
                const previousTime = vt[vt.length - 1];
                // Only add if currentTime is after the previous time to avoid overllaping periods
                if ((previousTime && currentTime.isAfter(moment.utc(previousTime, 'hh:mm A'))) || !previousTime) {
                  vt.push(currentTime.format('hh:mm A'));
                }
                currentTime.add(gapTime, 'minutes');
              }
            });
            return vt;
          };

          const getValidTimesIfIsHoliday = (vt: string[]) => {
            if (store.holidays && store.holidays.length) {
              const dateHour1 = moment.utc(date.clone().hour(1));
              const holiday = store.holidays.find((h) => {
                if (!h.is_disabled) {
                  if (!h.is_date_range) {
                    return moment.utc(h.holiday_date).isSame(dateHour1, 'day');
                  } else {
                    const startDate = h.schedule.startDate;
                    const endDate = h.schedule.endDate;
                    if (startDate && endDate) {
                      if (dateHour1.isBetween(moment.utc(startDate).startOf('day'), moment.utc(endDate).endOf('day'))) {
                        return true;
                      }
                    } else {
                      return false;
                    }
                  }
                }
                return false;
              });
              if (holiday) {
                if (!holiday.is_date_range || holiday.schedule.always_available) {
                  return [];
                } else {
                  const dayName = date.format('ddd');
                  const weekDay = getWeekDay(holiday.schedule.schedule_days, dayName);
                  if (weekDay && weekDay.is_active) {
                    return getValidTimesForDay(weekDay, schedule);
                  } else {
                    return [];
                  }
                }
              }
            }
            return vt;
          };
          const getValidTimesBeforeCurrent = (vt: string[]) => {
            const todayAtStoreTz = fromUTC(store.time_zone_offset_minutes, moment.utc());
            const isToday = date.isSame(todayAtStoreTz, 'day');
            const nowPlusQuote = todayAtStoreTz.clone().add(prepTime, 'minutes');
            if (isToday) {
              return vt.filter((time) => {
                const day = todayAtStoreTz.format('YYYY-MM-DD');
                const timeAtStoreDay = moment.utc(day + ' ' + time, 'YYYY-MM-DD HH:mm A');
                return timeAtStoreDay.isAfter(nowPlusQuote);
              });
            }
            return vt;
          };
          const prepTime = getPrepTime(currentOrder);
          const dayName = date.format('ddd');
          const weekDay = getWeekDay(getActiveDays(orderType), dayName);
          if (weekDay) {
            const _validTimes = getValidTimesForDay(weekDay, schedule);
            const _validTimesHoliday = getValidTimesIfIsHoliday(_validTimes);
            const _validTimesBeforeCurrent = getValidTimesBeforeCurrent(_validTimesHoliday);
            if (_validTimesHoliday < _validTimes) {
              holidayWarning = true;
            }
            if (_validTimes.length > 0 && _validTimesHoliday.length > 0 && _validTimesBeforeCurrent.length > 0) {
              validTimes = _validTimesBeforeCurrent;
            } else {
              validTimes = null;
              error = 'Online Ordering Is Unavailable.';
            }
          } else {
            validTimes = null;
            error = 'Online ordering is currently unavailable.';
          }
        } else {
          validTimes = null;
          error = 'Online ordering is not available for this day.';
        }
      }
      return {
        validTimes,
        error,
        holidayWarning,
      };
    };
    const getPrepTime = (currentOrder?: Order) => {
      const getPrepTimeDay = (prepTime: PrepTime) => {
        const todayAtStoreTz = fromUTC(store.time_zone_offset_minutes, moment.utc());
        const dayName = todayAtStoreTz.format('ddd');
        const prepTimeDay = prepTime.prepTime_days.find(
          (prepTimeDay) => prepTimeDay.day === dayName && prepTimeDay.is_active
        );
        return prepTimeDay ? { ...prepTimeDay } : undefined;
      };
      const getItemPrepTime = (item: Item) => {
        return this.currentMenu?.items.find((_item) => item.item_id === _item.id)?.prepTime;
      };
      const loopItemsAndGetLongestPrepTime = (items: Item[]) => {
        let prepTime = 0;
        items.forEach((item) => {
          const itemPrepTime = getItemPrepTime(item);
          if (itemPrepTime) {
            const prepTimeDay = getPrepTimeDay(itemPrepTime);
            if (prepTimeDay) {
              if (item.modifiers?.length) {
                const modifiersPrepTime = loopItemsAndGetLongestPrepTime(item.modifiers);
                if (modifiersPrepTime > parseInt(prepTimeDay?.preptime || '0', 10)) {
                  prepTimeDay.preptime = modifiersPrepTime.toString();
                }
              }
              if (parseInt(prepTimeDay?.preptime || '0', 10) > prepTime) {
                prepTime = parseInt(prepTimeDay.preptime, 10);
              }
            }
          }
        });
        return prepTime;
      };
      const getMenusPrepTime = () => {
        const menuPrepTimes: {
          [menu_id: string]: { prepTime: PrepTime };
        } = {};
        let date: string;
        if (currentOrder && currentOrder?.date_asked) {
          date = currentOrder.date_asked;
        } else {
          date = fromUTC(store.time_zone_offset_minutes, moment.utc()).format();
        }

        // Get distinct menus
        const menus = new Map();
        const groupsIds = currentOrder?.items?.map((item) => item.group_id);
        this.currentMenu?.menus.forEach((menu) => {
          const menuGroupsIds = menu.groups.map((g) => g.id);
          if (menuGroupsIds.some((mgid) => groupsIds?.includes(mgid))) {
            menus.set(menu.id, menu);
          }
        });
        Array.from(menus.values()).forEach(({ id, prepTime, schedule, groups }) => {
          if (isDateOnSchedule(schedule as Schedule, date, store.time_zone_offset_minutes)) {
            const mapGroups = new Map<string, string[]>();
            (groups as Group[]).forEach((group) => {
              mapGroups.set(group.id as string, group.items?.map((item) => item.id) ?? []);
            });
            if (currentOrder && currentOrder?.items?.length > 0) {
              currentOrder.items.forEach((item) => {
                const itemGroup = mapGroups.get(item.group_id);
                if (itemGroup && itemGroup.includes(item.item_id)) {
                  menuPrepTimes[id as string] = { prepTime: { ...(prepTime as PrepTime) } };
                }
              });
            } else {
              menuPrepTimes[id as string] = { prepTime: { ...(prepTime as PrepTime) } };
            }
          }
        });
        return Object.values(menuPrepTimes);
      };

      let prepTime = 0;
      if (currentOrder && currentOrder.items?.length) {
        prepTime = loopItemsAndGetLongestPrepTime(currentOrder.items);
      }
      const prepTimesQueue = [
        ...getMenusPrepTime(),
        store,
        store.order_options.find((op) => op.order_type?.toLowerCase?.() === currentOrder?.order_type?.toLowerCase?.()),
      ];
      prepTimesQueue.forEach((withPrepTime) => {
        if (withPrepTime && withPrepTime.prepTime) {
          const menuPrepTime = getPrepTimeDay(withPrepTime.prepTime);
          if (menuPrepTime && parseInt(menuPrepTime.preptime || '0', 10) > prepTime) {
            prepTime = parseInt(menuPrepTime.preptime, 10);
          }
        }
      });
      return prepTime;
    };
    const getAsapAvailable = (orderType: OrderType, currentOrder?: Order) => {
      const nowAtStoreTz = fromUTC(store.time_zone_offset_minutes, moment.utc());
      const dayName = nowAtStoreTz.format('ddd');
      const weekDay = getWeekDay(getActiveDays(orderType), dayName);

      const orderOption = store.order_options.find(
        (option) => option.order_type === orderType && option.enabled && !option.is_deleted
      );

      if (weekDay && orderOption?.allow_asap) {
        const timesArray = getValidTimes(nowAtStoreTz, orderType, currentOrder);
        if (timesArray.validTimes && timesArray.validTimes.length > 0) {
          const prepTime = getPrepTime(currentOrder);
          const periods = getPeriods(getOrderTypeSchedule(orderType), dayName as Weekday);
          const available = periods.some((period) => {
            const [openHours, openMinutes] = period.start.split(':');
            const [closeHours, closeMinutes] = period.end.split(':');
            const periodOpenTime = nowAtStoreTz.clone().set({
              hours: parseInt(openHours),
              minutes: parseInt(openMinutes),
              seconds: 0,
            });
            const periodCloseTime = nowAtStoreTz.clone().set({
              hours: parseInt(closeHours),
              minutes: parseInt(closeMinutes),
              seconds: 0,
            });
            const nowPlusPrepTime = nowAtStoreTz.clone().add(prepTime, 'minutes');
            return nowPlusPrepTime.isAfter(periodOpenTime) && nowPlusPrepTime.isBefore(periodCloseTime);
          });
          return {
            available,
            prepTime,
          };
        }
      }

      return {
        available: false,
        prepTime: null,
      };
    };

    const utilsType = store.order_options
      .filter((op) => op.enabled && !op.is_deleted)
      .reduce(
        (acc, orderOption) => ({
          ...acc,
          [orderOption.order_type]: { schedule: getOrderTypeSchedule(orderOption.order_type) },
        }),
        {} as StoreUtilsTypes
      );

    const utils: StoreUtils = {
      ...utilsType,
      is_disabled: getIsDisabled(),
      receiver_field: getReceiverField(),
      allow_payment_on_delivery: getAllowPaymentOnDelivery(),
      contact_email: getContactMail(),
      customizable_fee: getCustomizableFee(),
      tzOffset: store.time_zone_offset_minutes,
      defaultTipAmount: store.default_tip_amount,
      getAsapAvailable,
      getOrderOptions,
      getActiveDays,
      getValidTimes,
    };
    return utils;
  }

  private getCompanyGroups() {
    return this._getCompanyGroups().pipe(map((response) => response.Data));
  }

  private _getCompanyGroups() {
    // @TODO USE CACHE
    return this.http.get<Response<SystemCompanyGroup>>('CompanyGroup');
  }

  private _getCompanies() {
    const getTheme = (cl: NewCompany) => {
      let theme = cl?.extended_data?.Customizations?.find((c) => c.IsActive === true);
      if (theme) {
        try {
          theme = JSON.parse(theme.CustomizationObject as string);
        } catch {
          theme = undefined;
        }
      }
      return theme ?? null;
    };
    const transformCompanyLookup = map((response: Response<NewCompany>) => {
      const newResponse: Response<Company> = {
        ...response,
        Data: response.Data.map(
          (newCompanyLookup) =>
            ({
              company_id: newCompanyLookup.id,
              subdomain: newCompanyLookup.subdomain,
              subdomain_orig: '',
              company_name: newCompanyLookup.name,
              company_logo: newCompanyLookup.logo,
              theme: getTheme(newCompanyLookup),
              groupId: newCompanyLookup.groupId,
            } as Company)
        ),
      };
      return newResponse;
    });
    if (this.useCache) {
      const url = 'apiCache/Store/CompanyLookup';
      return this.http.get<Response<NewCompany>>(url, {}).pipe(transformCompanyLookup);
    } else {
      return this.http.post<Response<NewCompany>>('Store/CompanyLookup', {}).pipe(transformCompanyLookup);
    }
  }

  private _getStores(company: Company) {
    if (this.useCache) {
      return this.http.get<Response<Store>>(`apiCache/Store/${company.subdomain_orig}`, {});
    } else {
      return this.http.post<Response<Store>>('Store/' + company.subdomain, {});
    }
  }

  private _getMenu(store: Store) {
    const companyId = this.currentCompany?.company_id;
    if (this.useCache) {
      return this.http.get<ResponseObj<MenuResponse>>(`apiCache/Menu/${store.id}?companyId=${companyId}`, {});
    } else {
      return this.http.get<ResponseObj<MenuResponse>>(`Menu/${store.id}?companyId=${companyId}`, {});
    }
  }

  public setMenuFromAgg(newMenu: MenuData) {
    if (this.currentMenu) {
      const merge = <T extends { id: string }>(arr: T[], newArr: T[]): T[] => {
        const ids = arr.map((item) => item.id);
        return [...arr, ...newArr.filter((i) => !ids.includes(i.id))];
      };
      this._currentMenu.next({
        ...this.currentMenu,
        menus: merge(this.currentMenu.menus, newMenu.menus),
        groups: merge(this.currentMenu.groups, newMenu.groups),
        items: merge(this.currentMenu.items, newMenu.items),
        modifierGroups: merge(this.currentMenu.modifierGroups, newMenu.modifierGroups),
      });
    }
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
