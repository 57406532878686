import { Component } from '@angular/core';
import { notNull } from '@app/@shared/util-functions';
import { filter } from 'rxjs/operators';
import { HeaderBaseComponent } from '../../header.base-component';

@Component({
  selector: 'olo-header',
  templateUrl: './olo-header.component.html',
  styleUrls: ['./olo-header.component.scss'],
})
export class OloHeaderComponent extends HeaderBaseComponent {}
