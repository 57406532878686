import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/@shared';
import { ThemeModule } from '@app/@core/theme/theme.module';
import { HeaderTemplateSelector } from './templates/header.template-selector';
import { DefaultHeaderComponent } from './templates/default/default-header.component';
import { OloHeaderComponent } from './templates/olo/olo-header.component';
import { StyledComponentsModule } from '@app/@shared/components/styled-components.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, SharedModule, ThemeModule, StyledComponentsModule, RouterModule, TranslateModule],
  declarations: [HeaderTemplateSelector, OloHeaderComponent, DefaultHeaderComponent],
  exports: [HeaderTemplateSelector],
})
export class HeaderModule {}
