import { CSS } from '@app/@core/theme/model/theme-config';
import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';

export interface HeaderThemeConfig {
  header: {
    template: 'default' | 'olo';
  };
}
export const defaultHeaderThemeConfig: HeaderThemeConfig = {
  header: {
    template: 'default',
  },
};
export const headerC11nMetadata: CustomizableGroup = {
  title: 'Header',
  name: 'header',
  items: [
    {
      title: 'Template',
      name: 'template',
      type: CustomizableItemType.SELECT,
      options: [
        {
          label: 'Default',
          value: 'default',
        },
        {
          label: 'Olo',
          value: 'olo',
        },
      ],
    },
  ],
};
