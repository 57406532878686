import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItemPrice, getSubdomain } from '@app/@shared/util-functions';
import { environment } from '@env/environment';
import { compressToBase64, decompressFromBase64 } from 'lz-string';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCookie } from 'src/argus/utils';
import { IItem, Item } from '../models/item/item.model';
import { MenuResponse } from '../models/menu/menu.model';
import { IOrder, Order } from '../models/order/order.model';
import { ResponseObj } from '../models/response.model';
import { parse, ParsedDomain } from 'psl';
import { CompanyService } from './company.service';

interface ItemIdentifier {
  itemId: string;
  groupId: string;
  menuId: string;
  companyId: string;
  locationId: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrderAggregatorService {
  private subs: Subscription = new Subscription();
  private menuByStoreId: {
    [key: number]: Promise<MenuResponse>;
  } = {};

  public get useCache() {
    // TODO introduce a local storage angular service
    const useCacheLocalSetting = localStorage.getItem('cache');
    // the default
    let useCache = (environment.production && !environment.preRelease) || environment.version?.includes('-dev');
    if (useCacheLocalSetting || useCacheLocalSetting === '') {
      useCache = useCacheLocalSetting === 'true' ? true : false;
    }
    return useCache;
  }
  constructor(private http: HttpClient, private companyService: CompanyService) {}

  saveCartInCookies(order: IOrder, cid: string, sid: string) {
    if (order.items) {
      const items = order.items.map((item) => ({
        id: item.item_id,
        gid: item.group_id,
        mid: item.menu_id,
        q: item.quantity,
        cid: item.company_id ?? cid,
        sid: item.store_id ?? sid,
        m: item.modifiers.map((mod) => ({
          id: mod.item_id,
          gid: mod.group_id,
          pid: mod.parent_item_id,
          ps: mod.pizza_side,
        })),
      }));
      const smallOrder = {
        ia: order.is_asap,
        s: order.scheduled_time,
        items,
      };
      const { domain } = parse(window.document.location.hostname) as ParsedDomain;
      const now = new Date();
      now.setMinutes(now.getMinutes() + 10);
      document.cookie =
        'bbweb-cart=' +
        compressToBase64(JSON.stringify(smallOrder)) +
        '; path=/; expires=' +
        now.toUTCString() +
        '; domain=.' +
        domain;
    }
  }

  async getOrderFromCookies(order: IOrder): Promise<Order> {
    const new_order = new Order(order);
    let c_start = document.cookie.indexOf('bbweb-cart=');
    if (c_start != -1) {
      c_start = c_start + 'bbweb-cart='.length;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      const prevOrder = JSON.parse(decompressFromBase64(document.cookie.substring(c_start, c_end)) as string);
      new_order.is_asap = prevOrder.ia;
      new_order.scheduled_time = prevOrder.s;
      const items_promises = prevOrder.items.map(async (_item: any) => {
        const fullItem = await this.getFullItem({
          itemId: _item.id,
          groupId: _item.gid,
          menuId: _item.mid,
          companyId: _item.cid,
          locationId: _item.sid,
        });
        const item = {
          ...fullItem,
          company_id: _item.cid,
          store_id: _item.sid,
          item_id: _item.id,
          group_id: _item.gid,
          quantity: _item.q,
          image: fullItem ? fullItem.images?.[0] : null,
          modifiers: await Promise.all(
            _item.m.map(async (_mod: any) => {
              const fullMod = await this.getFullItem(
                {
                  itemId: _mod.id,
                  groupId: _mod.gid,
                  menuId: _mod.mid,
                  companyId: _item.cid,
                  locationId: _item.sid,
                },
                true
              );
              return {
                ...fullMod,
                item_id: _mod.id,
                group_id: _mod.gid,
                parent_item_id: _mod.pid ?? '',
                pizza_side: _mod.ps,
                quantity: 1,
              };
            })
          ),
        } as IItem;
        return item;
      });
      new_order.items = await Promise.all(items_promises);
    }
    return new_order;
  }

  async getFullItem(id: ItemIdentifier, modOption: boolean = false) {
    if (!this.menuByStoreId[id.locationId])
      this.menuByStoreId[id.locationId] = firstValueFrom(
        this._getMenu(id.locationId).pipe(
          map((r) => {
            const formatted = this.companyService.formatMenuResponse(r.Data, id.locationId.toString());
            this.companyService.setMenuFromAgg(formatted);
            return r.Data;
          })
        )
      );
    const menu = await this.menuByStoreId[id.locationId];
    return menu[modOption ? 'modifier_options' : 'items'].find((item) => item.id === id.itemId);
  }

  removeOrderFromCookie() {
    const { domain } = parse(window.document.location.hostname) as ParsedDomain;
    document.cookie = 'bbweb-cart=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.' + domain;
  }

  private _getMenu(store_id: number) {
    const companyId = this.companyService.currentCompany?.company_id;
    if (this.useCache) {
      return this.http.get<ResponseObj<MenuResponse>>(`apiCache/Menu/${store_id}?companyId=${companyId}`, {});
    } else {
      return this.http.get<ResponseObj<MenuResponse>>(`Menu/${store_id}?companyId=${companyId}`, {});
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
