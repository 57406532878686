export class IVehicle {
  make: string | null;
  model: string | null;
  color: string | null;
}

export class Vehicle implements IVehicle {
  public make: string | null;
  public model: string | null;
  public color: string | null;

  constructor(obj: IVehicle) {
    Object.entries(obj).forEach(([key, value]) => Object.assign(this, { [key]: value }));
  }
}

export class CustomerVehicle implements IVehicle {
  public color: string | null;
  public license_plate: string | null;
  public make: string | null;
  public model: string | null;
  public notes: string | null;
  public year: string | null;

  constructor(obj: IVehicle) {
    Object.entries(obj).forEach(([key, value]) => Object.assign(this, { [key]: value }));
  }
}
