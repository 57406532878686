import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { Response } from '@app/@core/models/response.model';
import { catchError, concatMap, filter, first, last, map, switchMap } from 'rxjs/operators';
import { getSubdomain, notNull } from '@app/@shared/util-functions';
import { NewCompany } from '../models/company/company.model';
import { CompanyService } from '../services/company.service';
import { AuthService } from '../services/auth.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.backends[companySubdomain].url`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  private subdomainBackendMap: {
    [key: string]: {
      label: string;
      url: string;
      cacheUrl: string;
    };
  } = {};
  private publicApis = [
    'auth/',
    'Store/CompanyLookup',
    'Store/',
    'Menu/',
    'guest/auth',
    'guest/refresh-token',
    'guest/get-order-and-token',
    'discount/',
    'order/status',
    'loginjwt/',
    'delivery/',
    'Analytics/',
    {
      url: 'customization/',
      method: 'GET',
    },
    'CompanyGroup',
  ];
  constructor(private authService: AuthService, private companyService: CompanyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const backends = environment.backends;

    if (!/^(http|https):/i.test(request.url)) {
      let endpoint = request.url.startsWith('apiCache/') ? request.url.replace('apiCache/', '') : request.url;
      const isPublicApi = this.publicApis.some((pa) => {
        if (typeof pa === 'object') {
          return request.method === pa.method && request.url.includes(pa.url);
        }
        return request.url.includes(pa);
      });
      if (this.authService.authenticatedAsGuest && !isPublicApi) {
        endpoint = 'guest/' + endpoint;
      }
      // If this is a company lookup request, queue every backend company lookup
      if (request.url.includes('Store/CompanyLookup')) {
        const companyLookupRequests = backends.map((backend) => {
          const backendUrl = request.url.startsWith('apiCache/') ? backend.cacheUrl : backend.url;
          return request.clone({
            url: backendUrl + endpoint,
          });
        });

        // Concat companies from all backends, and emit all of them only once
        let response: HttpResponse<Response<NewCompany>>;
        return from(companyLookupRequests).pipe(
          concatMap((req, index) =>
            next.handle(req).pipe(
              map((event) => {
                if (event.type === HttpEventType.Response) {
                  // Save which backend to use per company
                  (event as typeof response).body?.Data.forEach((company) => {
                    const backend = backends[index];
                    if (backend.label === 'bizblocks-dev') {
                      company.subdomain += '-dev';
                    }
                    this.subdomainBackendMap[company.subdomain.toLowerCase()] = backend;
                  });

                  // Concat comapnies
                  if (response) {
                    response.body?.Data.push(...event.body.Data);
                  } else {
                    response = event;
                  }
                  return response;
                }
                return event;
              }),
              catchError(() => {
                return of(response);
              })
            )
          ),
          last()
        );
      } else {
        // Company Group requests are independent for now
        if (request.url.startsWith('CompanyGroup')) {
          return next.handle(
            request.clone({
              url: backends.find((be) => be.label === 'bizblocks')?.url + endpoint,
            })
          );
        }
        return this.companyService.currentCompany$.pipe(
          filter(notNull),
          first(),
          switchMap(() => {
            // Use the saved backend for all other requests
            const currentSubdomain = getSubdomain();
            const backend = this.subdomainBackendMap[currentSubdomain];
            if (backend.label === 'bizblocks-dev') {
              endpoint = endpoint.replace(/-dev/g, '');
            }
            const backendUrl = request.url.startsWith('apiCache/') ? backend.cacheUrl : backend.url;
            return next.handle(
              request.clone({
                url: backendUrl + endpoint,
              })
            );
          })
        );
      }
    }

    return next.handle(request);
  }
}
