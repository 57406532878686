import { Component } from '@angular/core';
import { HeaderTemplateFactory } from './header.template-factory';

@Component({
  selector: 'app-header',
  template: `<multiple-template [templateFactory]="templateFactory"></multiple-template>`,
  styleUrls: [],
  providers: [HeaderTemplateFactory],
})
// tslint:disable-next-line: component-class-suffix
export class HeaderTemplateSelector {
  constructor(public templateFactory: HeaderTemplateFactory) {}
}
