import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';
import { captureException, canLog } from '../argus';

export class ArgusErrorHandler implements ErrorHandler {
  constructor() {
    console.log('Argus Error Handler init');
  }

  public handleError(errorCandidate: unknown) {
    let error = this.extractErrorInfo(errorCandidate);
    if (canLog) captureException(error);
    throwError(error);
  }

  protected extractErrorInfo(errorCandidate: unknown) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (errorCandidate && (errorCandidate as { ngOriginalError: Error }).ngOriginalError) {
      errorCandidate = (errorCandidate as { ngOriginalError: Error }).ngOriginalError;
    }
    // We can handle messages and Error objects directly.
    if (typeof errorCandidate === 'string' || errorCandidate instanceof Error) {
      return errorCandidate;
    }

    // If it's http module error, extract as much information from it as we can.
    if (errorCandidate instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (errorCandidate.error instanceof Error) {
        return errorCandidate.error;
      }
      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (errorCandidate.error instanceof ErrorEvent && errorCandidate.error.message) {
        return errorCandidate.error.message;
      }
      // ...or the request body itself, which we can use as a message instead.
      if (typeof errorCandidate.error === 'string') {
        return `Server returned code ${errorCandidate.status} with body "${errorCandidate.error}"`;
      }
      // If we don't have any detailed information, fallback to the request message itself.
      return errorCandidate.message;
    }
    // Nothing was extracted, fallback to default error message.
    return null;
  }
}

export function createErrorHandler() {
  return new ArgusErrorHandler();
}
