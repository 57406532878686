import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderType } from '@app/@core/models/order/order.model';
import { AuthService } from '@app/@core/services/auth.service';
import { CompanyService } from '@app/@core/services/company.service';
import { CustomerService } from '@app/@core/services/customer.service';
import { OrderService } from '@app/@core/services/order.service';
import { ThemeService } from '@app/@core/theme/service/theme.service';
import { getBaseHost, getDeliveryEstimate, notNull } from '@app/@shared/util-functions';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  template: '',
})
export class HeaderBaseComponent implements OnInit, OnDestroy {
  public location: string;
  public fromGroup: boolean;
  public orderSuccess: boolean;
  public background: string;
  public deliveryEstimate: number;
  public loadingToken$: Observable<boolean>;
  public isAuthenticated$: Observable<string>;
  public isAdmin$: Observable<boolean>;
  public currentOrderLength$: Observable<number>;
  public get currentOrder() {
    return this.orderService.currentOrder;
  }
  public get synchronizingOrder() {
    return this.orderService.synchronizingOrder;
  }
  public get isGuest() {
    if (sessionStorage.getItem('APP-GUEST') && sessionStorage.getItem('APP-GUEST') === 'true') {
      return true;
    } else {
      return false;
    }
  }
  public get isNotMenuPage() {
    return !this.router.url.includes('menu');
  }
  public get currentOrderType() {
    if (!this.currentOrder || !this.companyService.currentStore) {
      return undefined;
    }
    let orderType: string;
    let deliveryEstimate = 0;
    let category: string | undefined = this.companyService.currentStore?.order_options.find(
      (op) => op.order_type === this.currentOrder?.order_type
    )?.name;

    const { getAsapAvailable } = this.companyService.getStoreUtils(this.companyService.currentStore);
    let { prepTime } = getAsapAvailable(this.currentOrder?.order_type, this.currentOrder);

    if (this.currentOrder?.order_type === OrderType.DELIVERY && prepTime) {
      deliveryEstimate = this.deliveryEstimate;
    }

    if (!prepTime) prepTime = 0;
    if (!deliveryEstimate) deliveryEstimate = 0;
    const isAsap = this.currentOrder?.is_asap
      ? `ASAP ${prepTime + deliveryEstimate > 0 ? `(${prepTime + deliveryEstimate} mins)` : ''}`
      : 'Scheduled';
    orderType = `${category}, ${isAsap}`;
    return orderType;
  }
  public get OrderTypeIcon() {
    let icon: string;
    switch (this.currentOrder?.order_type) {
      case OrderType.DINE_IN:
        icon = 'fa fa-utensils';
        break;
      case OrderType.DELIVERY:
        icon = 'fa fa-map-marker-alt';
        break;
      case OrderType.CURBSIDE:
        icon = 'fa fa-car';
        break;
      case OrderType.PICKUP:
        icon = 'fa fa-shopping-bag';
        break;
      default:
        icon = '';
    }
    return icon;
  }

  private subs: Subscription = new Subscription();

  constructor(
    public orderService: OrderService,
    public companyService: CompanyService,
    public authService: AuthService,
    public customerService: CustomerService,
    public themeService: ThemeService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  getLogo() {
    return this.themeService.theme?.logo ?? this.companyService.currentCompany?.company_logo;
  }

  ngOnInit(): void {
    this.isAdmin$ = this.authService.isAdmin();
    this.authService.token$.subscribe((token) => {
      if (token && !this.authService.authenticatedAsGuest) {
        this.isAuthenticated$ = of('AUTHENTICATED');
      } else {
        this.isAuthenticated$ = of('UNAUTHENTICATED');
      }
    });
    this.currentOrderLength$ = this.orderService.currentOrder$.pipe(
      filter(notNull),
      map((order) => order.items.reduce((acc: any, curr: any) => acc + curr.quantity, 0))
    );
    this.route.queryParams.subscribe((params) => {
      this.orderSuccess = params.orderSuccess;
      if (!this.fromGroup) {
        this.fromGroup = params.fromGroup;
      }
      sessionStorage.setItem('fromGroup', 'true');
    });
    this.subs.add(
      this.companyService.currentStore$
        .pipe(
          filter(notNull),
          switchMap(({ id }) => getDeliveryEstimate(this.orderService.currentOrder$, id, this.http))
        )
        .subscribe((deliveryEstimate) => {
          this.deliveryEstimate = deliveryEstimate;
        })
    );
    if (sessionStorage.getItem('fromGroup')) {
      this.fromGroup = true;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  showStoreInfo() {
    const isMobile = window.document.body.clientWidth < 763;
    if (isMobile) {
      this.router.navigate([{ outlets: { modal: 'current-order-details' } }], { queryParams: { showStoreInfo: true } });
    }
  }

  logout() {
    this.orderService.setCurrentOrderCustomer(undefined);
    this.authService.logout();
  }

  login() {
    this.authService.openLogin();
  }

  openAccount() {
    this.router.navigate([{ outlets: { modal: 'account' } }]);
  }

  openAddresses() {
    this.router.navigate([{ outlets: { modal: 'addresses' } }], { queryParams: { origin: this.router.url } });
  }

  goToCart() {
    this.router.navigate([{ outlets: { modal: 'cart' } }]);
  }

  openHistory() {
    this.router.navigate([{ outlets: { modal: 'order-history' } }]);
  }

  openFavorites() {
    this.router.navigate([{ outlets: { modal: 'account' } }], { queryParams: { activeTab: 'favorites' } });
  }

  verifyRouteAndGoBack() {
    if (this.isNotMenuPage) {
      if (this.orderService.isEdit) {
        this.orderService.resetOrder();
      } else if (this.orderSuccess) {
        this.router.navigate(['/' + this.companyService.currentStore?.url_path + '/menu']).then(() => {
          this.router.navigate([{ outlets: { modal: 'current-order-details' } }]);
        });
      } else {
        this.router.navigate([`${this.router.url.split('/')[1]}/menu`]);
      }
    } else {
      if (this.fromGroup && this.companyService.currentCompany?.groupId) {
        let newhref = `${window.location.protocol}//${getBaseHost()}/company-groups/${
          this.companyService.currentCompany.groupId
        }/stores`;

        const companyGroup = this.companyService.companyGroupList?.find(
          (cgl) => cgl.id === this.companyService.currentCompany?.groupId
        );
        if (companyGroup?.subdomain) {
          newhref = `${window.location.protocol}//${companyGroup?.subdomain}.${getBaseHost()}`;
        }
        window.location.href = newhref;
      } else {
        this.router.navigate(['/stores']);
      }
    }
  }

  openCurrentOrderDetails() {
    this.router.navigate([{ outlets: { modal: 'current-order-details' } }]);
  }
}
