<ng-container *ngIf="companyService.currentCompany$ | async">
  <ng-container *ngIf="companyService.currentStore$ | async as currentStore">
    <div class="DefaultHeader">
      <div class="DefaultHeader-left d-flex">
        <a routerLink="/" class="DefaultHeader-left-logo">
          <img
            *ngIf="getLogo()"
            [src]="getLogo()"
            [alt]="companyService.currentCompany?.company_name + 'logo'"
            class="Checkout-header"
          />
          <span *ngIf="!getLogo()">{{ companyService.currentCompany?.company_name }}</span>
        </a>
        <button
          id="backIcon"
          style="cursor: pointer"
          class="DefaultHeader-left-back-icon"
          (click)="verifyRouteAndGoBack()"
          title="Back"
          role="button"
          aria-label="back to store picker"
        >
          <i class="fas fa-angle-left" aria-hidden="true"></i>
        </button>
        <div
          class="DefaultHeader-left-text"
          *ngIf="!isNotMenuPage && companyService.currentStore?.name"
          (click)="showStoreInfo()"
        >
          <span>{{ 'SHELL.' + 'Ordering from' | translate }}</span>
          <span class="DefaultHeader-left-text-highlight">
            {{ companyService.currentStore?.name }}
          </span>
        </div>
        <div class="DefaultHeader-left-text" *ngIf="isNotMenuPage">
          <span> Back to </span>
          <span class="DefaultHeader-left-text-highlight"> Menu </span>
        </div>
      </div>
      <div class="DefaultHeader-center">
        <div class="d-none d-sm-flex flex-column align-items-center small">
          <span *ngIf="currentStore?.address.phone">{{ currentStore?.address.phone }}</span>
          <span *ngIf="currentStore?.contact_email">{{ currentStore?.contact_email }}</span>
          <div>
            <span *ngIf="currentStore?.address.line1">{{ currentStore?.address.line1 }}</span>
            <span *ngIf="currentStore?.address.line2">{{ currentStore?.address.line2 }}</span>
            <span *ngIf="currentStore?.address.city">
              {{ currentStore?.address.city }}<span *ngIf="currentStore?.address.region">, </span>
            </span>
            <span *ngIf="currentStore?.address.region">{{ currentStore?.address.region }}</span>
          </div>
        </div>
      </div>
      <div class="DefaultHeader-buttons text-right">
        <ng-container *ngIf="!isNotMenuPage && currentOrderType">
          <button
            class="d-sm-inline btn btn-sm btn-primary"
            (click)="openCurrentOrderDetails()"
            [attr.aria-label]="'change order options button, current options' + currentOrderType"
          >
            <i [ngClass]="OrderTypeIcon" aria-hidden="true"></i>
            <span class="d-none d-sm-inline badge">{{ currentOrderType }}</span>
          </button>
        </ng-container>
        <ng-container *ngIf="(isAuthenticated$ | async) === 'AUTHENTICATED' && !isGuest && !isNotMenuPage">
          <button
            class="d-none d-sm-inline btn btn-sm btn-primary"
            (click)="openHistory()"
            aria-label="orders history button"
          >
            <i class="fas fa-sticky-note" aria-hidden="true"></i>
            <span class="badge badge-primary">{{ 'SHELL.' + 'Orders' | translate }}</span>
          </button>
        </ng-container>
        <ng-container>
          <button
            class="btn btn-sm btn-primary"
            (click)="goToCart()"
            [disabled]="currentOrder === null"
            *ngIf="(currentOrder !== null && currentOrder.items.length > 0) || synchronizingOrder === true"
            [attr.aria-label]="'cart button, total items on cart' + currentOrder?.items?.length"
          >
            <i class="fas fa-shopping-cart" aria-hidden="true"></i>
            <span class="badge badge-primary">
              {{ currentOrder ? (currentOrderLength$ | async) : '...' }}
            </span>
          </button>
        </ng-container>
        <ng-container *ngIf="isAuthenticated$ | async as isAuthenticated">
          <div class="btn-group" dropdown *ngIf="isAuthenticated === 'AUTHENTICATED'">
            <button
              class="btn btn-sm btn-light dropdown-toggle"
              id="account-button"
              type="button"
              aria-label="account dropdown"
              dropdownToggle
            >
              <i class="fa fa-bars" aria-hidden="true"></i>
            </button>
            <ul
              class="dropdown-menu"
              id="account-dropdown"
              role="menu"
              aria-labelledby="account-button"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
            >
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openAccount()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-user mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Account' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openHistory()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-th-list mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Order History' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openFavorites()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-heart" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Favorites' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openAddresses()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-map-marker-alt mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Addresses' | translate }}</span>
                </span>
              </li>
              <li role="menuitem" *ngIf="isAdmin$ | async">
                <a class="dropdown-item" [routerLink]="'/customize'" style="cursor: pointer">
                  <i class="fas fa-pencil-ruler mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Customize' | translate }}</span>
                </a>
              </li>
              <li role="menuitem">
                <span class="dropdown-item" (click)="orderService.resetOrder()" style="cursor: pointer">
                  <i class="fas fa-sync mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Clear and reset order' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span class="dropdown-item" (click)="logout()" style="cursor: pointer">
                  <i class="fas fa-sign-out-alt mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Logout' | translate }}</span>
                </span>
              </li>
            </ul>
          </div>
          <button
            class="btn btn-sm btn-light shell-background remove-mobile-outline"
            *ngIf="isAuthenticated === 'UNAUTHENTICATED' || isAuthenticated === 'AUTHENTICATING'"
            (click)="login()"
            [disabled]="isAuthenticated === 'AUTHENTICATING'"
            aria-label="login button"
          >
            <ng-container *ngIf="isAuthenticated === 'UNAUTHENTICATED'">
              <i class="fa fa-sign-in-alt mr-2" aria-hidden="true"></i>
              <span class="login-span">{{ 'SHELL.' + 'Login' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="isAuthenticated === 'AUTHENTICATING'">
              <app-loader isLoading="true"></app-loader>
            </ng-container>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
