import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-suspense',
  templateUrl: './suspense.component.html',
  styleUrls: ['./suspense.component.scss'],
})
export class SuspenseComponent implements OnInit, OnDestroy {
  @Input() isLoading$: Observable<boolean>;
  @Input() loadingMessage: string | undefined;
  // @HOTFIX
  @Input() withSkeleton: boolean = false;
  @Input() wait: number = 10000;
  @Input() fallbackMessage: string = 'We had a problem loading this, try it again later.';

  public isLoading: boolean;
  public showError: boolean;

  private sub = new Subscription();

  ngOnInit() {
    if (this.isLoading$)
      this.sub = this.isLoading$.pipe(timeout(this.wait * 1000)).subscribe(
        (isLoading) => {
          this.isLoading = isLoading;
          this.showError = false;
        },
        () => {
          if (this.isLoading) {
            this.isLoading = false;
            this.showError = true;
          }
        }
      );
  }

  retry() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
