export interface CompanyGroupStorePickerThemeConfig {
  companyGroupStorePicker: {
    template: 'map-right';
  };
}
export const defaultCompanyGroupStorePickerThemeConfig: CompanyGroupStorePickerThemeConfig = {
  companyGroupStorePicker: {
    template: 'map-right',
  },
};
