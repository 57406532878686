import { AnalyticsEventType, Context, Contexts, Event, EventType, InitOptions, isExceptionEvent, User } from './types';
import { describeUserAgent, createUUID, getCookie, getUserIpAndLocation, setCookie } from './utils';

const SESSION_KEY = 'ARGUS_SESSION_KEY';
const CONTEXTS_KEY = 'ARGUS_CONTEXTS_KEY';

let user: User;
let contexts: Contexts = {};
export let canLog: boolean = false;

export function captureException(exception: unknown) {
  let message: string = 'An undefined error occurred';
  let stacktrace: string | undefined;

  if (exception instanceof Error) {
    message = exception.message;
    stacktrace = exception.stack;
  } else if (typeof exception === 'string') {
    message = exception;
    if (message.indexOf('script error') > -1) {
      message = 'An external script caused an error';
    }
  }
  sendEvent({ message, type: EventType.exception, stacktrace });
}

export function sendEvent({
  message,
  type,
  start_timestamp,
  stacktrace,
}: {
  message: string;
  type: EventType;
  start_timestamp?: number;
  stacktrace?: string;
  analytics_type?: AnalyticsEventType;
}) {
  const event: Event = {
    session_id: getCookie(SESSION_KEY),
    event_id: createUUID(),
    message,
    timestamp: new Date().getTime(),
    start_timestamp,
    type: type,
    user,
    contexts: contexts,
  };

  if (isExceptionEvent(event) && stacktrace) {
    event.stacktrace = stacktrace;
  }

  fetch('https://www.dev.bizblocks.net/api/Log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      Message: message,
      Level: type === EventType.exception ? 'Error' : type === EventType.analytics ? 'Information' : 'Warning',
      ForContextObjects: event,
    }),
  });
}

export async function setUser(context?: Context) {
  const { ip, location } = await getUserIpAndLocation();
  user = {
    host: window.location.hostname,
    path: window.location.pathname,
    device: {
      user_agent: describeUserAgent(),
      window_width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
      window_height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    },
    location: location,
    referring_url: document.referrer,
    ip_address: ip,
    extended: context,
  };
}

export function setContext(context: Context) {
  contexts = {
    ...contexts,
    ...context,
  };
  setCookie(CONTEXTS_KEY, JSON.stringify(contexts));
}

export function removeContext(context: string) {
  if (contexts?.hasOwnProperty('context')) delete contexts[context];
  setCookie(CONTEXTS_KEY, JSON.stringify(contexts));
}

export function init(
  { withErrorTracking, withRequestTracking, withUser, withContext }: InitOptions = {
    withErrorTracking: true,
    withUser: true,
    withRequestTracking: false,
  }
) {
  const sessionId = getCookie(SESSION_KEY);
  if (!sessionId) {
    setCookie(SESSION_KEY, createUUID());
  }

  const _contexts = getCookie(CONTEXTS_KEY);
  if (_contexts) {
    contexts = JSON.parse(_contexts);
  }

  if (withUser) {
    setUser(typeof withUser === 'object' ? withUser : undefined);
  }

  if (withContext) {
    setContext(withContext);
  }

  if (withErrorTracking) {
    window.onerror = function (_msg, _url, _lineNo, _columnNo, error) {
      captureException(error);
      return true;
    };
  }

  if (withRequestTracking) {
    function interceptNetworkRequests() {
      const open = XMLHttpRequest.prototype.open;
      const send = XMLHttpRequest.prototype.send;

      const isRegularXHR = open.toString().indexOf('native code') !== -1;
      if (isRegularXHR) {
        XMLHttpRequest.prototype.open = function () {
          this.addEventListener('load', (load) => {
            console.log('XMLHttpRequestLoad', load);
          });
          this.addEventListener('error', (err) => {
            console.log('XMLHttpRequestError', err);
          });
          return open.apply(this, arguments as any);
        };
        XMLHttpRequest.prototype.send = function () {
          console.log('XMLHttpRequest', arguments);
          return send.apply(this, arguments as any);
        };
      }

      const fetch = window.fetch || '';
      const isFetchNative = fetch.toString().indexOf('native code') !== -1;
      if (isFetchNative) {
        window.fetch = async function () {
          console.log('Fetch');
          const response = await fetch.apply(this, arguments as any);

          response
            .clone()
            .json()
            .then(() => console.log('FetchLoad'))
            .catch(() => console.log('FetchError'));
          return response;
        };
      }
    }

    interceptNetworkRequests();
  }

  canLog = true;
}
