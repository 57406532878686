// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  preRelease: false,
  hmr: false,
  version: env.npm_package_version,
  // signalrUrl: 'https://posportalswebcache.azurewebsites.net/api/',
  signalrUrl: 'https://func-suntek-ordering-notifications.azurewebsites.net/api/',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'es-ES', 'pt-BR'],
  google_client_id: '347578710820-4ss0uima7k2mfg0s3nuedkksbrjip4hs.apps.googleusercontent.com',
  facebook_app_id: '2884750601829946',
  backends: [
    {
      label: 'bizblocks',
      url: 'https://www.bizblocks.net/api/',
      cacheUrl: 'https://posportals.net/apiCache/bizblocks/',
    },
    {
      label: 'suntekreporting',
      url: 'https://www.suntekreporting.io/api/',
      cacheUrl: 'https://posportals.net/apiCache/suntekreporting/',
    },
  ],
};
