// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Other Imports
import { LoaderComponent } from './loader/loader.component';
import { NgxBootstrapModule } from './ngx-bootstrap.module';
import { TabsComponent } from './tabs/tabs.component';
import { ModalComponent } from './modal/modal.component';
import { DrawerComponent } from './drawer/drawer.component';
import { Pipes } from './pipes';
import { RangeComponent } from './range/range.component';
import { NgxMaskModule } from 'ngx-mask';
import { StyledComponentsModule } from './components/styled-components.module';
import { BraintreeDropInComponent } from './braintree/braintree.component';
import { ScrollSpyDirective } from './directives/scrollspy.directive';
import { SuspenseComponent } from './suspense/suspense.component';

@NgModule({
  declarations: [
    ...Pipes,
    DrawerComponent,
    LoaderComponent,
    TabsComponent,
    ModalComponent,
    RangeComponent,
    BraintreeDropInComponent,
    ScrollSpyDirective,
    SuspenseComponent,
  ],
  imports: [CommonModule, NgxBootstrapModule, FormsModule, NgxMaskModule, StyledComponentsModule],
  exports: [
    ...Pipes,
    DrawerComponent,
    LoaderComponent,
    TabsComponent,
    NgxBootstrapModule,
    FormsModule,
    ModalComponent,
    RangeComponent,
    BraintreeDropInComponent,
    ScrollSpyDirective,
    SuspenseComponent,
  ],
})
export class SharedModule {}
