import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticationGuard } from './auth/authentication.guard';
import { ShellComponent } from './components/shell/shell.component';

const publicRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./screens/company-picker/company-picker.module').then((m) => m.CompanyPickerModule),
  },
  {
    path: 'stores',
    loadChildren: () => import('./screens/store-picker/store-picker.module').then((m) => m.StorePickerModule),
  },
  {
    path: ':store/menu',
    loadChildren: () => import('./screens/menu/menu.module').then((m) => m.MenuModule),
  },
  {
    path: 'company-groups/:group/stores',
    loadChildren: () =>
      import('./screens/company-group-store-picker/company-group-store-picker.module').then(
        (m) => m.CompanyGroupStorePickerModule
      ),
  },
  {
    path: 'customize-item/:groupId/:itemId',
    outlet: 'modal',
    loadChildren: () => import('./screens/item-drawer/item-drawer.module').then((m) => m.ItemDrawerModule),
  },
  {
    path: 'company-not-found',
    loadChildren: () =>
      import('./screens/company-not-found/company-not-found.module').then((m) => m.CompanyNotFoundModule),
  },
  {
    path: 'current-order-details',
    outlet: 'modal',
    loadChildren: () =>
      import('./screens/current-order-details/current-order-details.module').then((m) => m.CurrentOrderDetailsModule),
  },
  {
    path: 'cart',
    outlet: 'modal',
    loadChildren: () => import('./screens/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: 'discounts',
    outlet: 'modal',
    loadChildren: () => import('./screens/discounts/discounts.module').then((m) => m.DiscountsModule),
  },
  {
    path: 'guest-checkout',
    outlet: 'modal',
    loadChildren: () => import('./screens/guest-checkout/guest-checkout.module').then((m) => m.GuestCheckoutModule),
  },
  {
    path: 'sign-up',
    outlet: 'modal',
    loadChildren: () => import('./screens/sign-up/sign-up.module').then((m) => m.SignUpModule),
  },
  {
    path: 'recover-password',
    outlet: 'modal',
    loadChildren: () =>
      import('./screens/recover-password/recover-password.module').then((m) => m.RecoverPasswordModule),
  },
  {
    path: 'theme',
    loadChildren: () => import('./screens/theme/theme.module').then((m) => m.ThemeModule),
  },
  {
    path: ':store/order-status/:id',
    loadChildren: () => import('./screens/order-status/order-status.module').then((m) => m.OrderStatusModule),
  },
  {
    path: 'addresses',
    outlet: 'modal',
    loadChildren: () => import('./screens/addresses/addresses.module').then((m) => m.AddressesModule),
  },
  {
    path: ':store/checkout',
    loadChildren: () => import('./screens/checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: ':store/view-order/:orderId',
    loadChildren: () => import('./screens/view-order/view-order.module').then((m) => m.ViewOrderModule),
  },
  {
    path: 'customize',
    loadChildren: () => import('./screens/customize/customize.module').then((m) => m.CustomizeModule),
  },
  {
    path: 'customize-wizard',
    loadChildren: () =>
      import('./screens/customize-wizard/customize-wizard.module').then((m) => m.CustomizeWizardModule),
  },
  {
    path: 'admin-login',
    loadChildren: () => import('./screens/admin-login/admin-login.module').then((m) => m.AdminLoginModule),
  },
  { path: '**', redirectTo: '/', pathMatch: 'prefix' },
];

const protectedRoutes: Routes = [
  {
    path: 'order-history',
    outlet: 'modal',
    loadChildren: () => import('./screens/order-history/order-history.module').then((m) => m.OrderHistoryModule),
  },
  {
    path: 'account',
    outlet: 'modal',
    loadChildren: () => import('./screens/account/account.module').then((m) => m.AccountModule),
  },
];

const getRoutes = (): Routes => {
  const _pr: Routes = [];
  const _pu: Routes = [];
  const _prModal: Routes = [];
  const _puModal: Routes = [];

  protectedRoutes.forEach((pr) => {
    pr = { ...pr, canActivate: [AuthenticationGuard] };
    if (pr.outlet === 'modal') {
      _prModal.push(pr);
    } else {
      _pr.push(pr);
    }
  });

  publicRoutes.forEach((pu) => {
    if (pu.outlet === 'modal') {
      _puModal.push(pu);
    } else {
      _pu.push(pu);
    }
  });

  return [
    {
      path: '',
      component: ShellComponent,
      children: [..._pr, ..._pu],
      data: { reuse: true },
    },
    ..._puModal,
    ..._prModal,
  ];
};

@NgModule({
  imports: [RouterModule.forRoot(getRoutes(), { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
