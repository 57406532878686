import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ADMIN_LOGIN_STORAGE_KEY } from '@app/auth/admin-authentication.guard';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { filter, switchMap } from 'rxjs/operators';
import { notNull } from '@app/@shared/util-functions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  publicApis: Array<string | { url: string; method: string }>;

  private token: string;

  constructor(private authService: AuthService, private router: Router) {
    this.publicApis = [
      'auth/',
      'Store/CompanyLookup',
      'Store/',
      'Menu/',
      'guest/auth',
      'guest/get-order-and-token',
      'guest/refresh-token',
      'discount/',
      'order/status',
      'loginjwt/',
      'delivery/',
      'Analytics/',
      {
        url: 'customization/',
        method: 'GET',
      },
      'CompanyGroup',
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isPublicApi = this.publicApis.some((pa) => {
      if (typeof pa === 'object') {
        return req.method === pa.method && req.url.toLowerCase().includes(pa.url.toLowerCase());
      }
      return req.url.toLowerCase().includes(pa.toLowerCase());
    });

    if (isPublicApi) {
      return next.handle(req);
    }

    // Customization handles auth in a different way
    if (req.url.includes('customization')) {
      const adminToken = sessionStorage.getItem(ADMIN_LOGIN_STORAGE_KEY);
      if (adminToken) {
        const headers = req.headers.set('Authorization', `Bearer ${adminToken}`);
        const requestClone = req.clone({ headers });
        return next.handle(requestClone);
      } else {
        this.router.navigate(['admin-login']);
      }
    }

    if (this.authService.isAuthenticated()) {
      this.token = this.authService.token;
      return this.setTokenAndGo(req, next);
    } else {
      this.authService.openLogin();
      return this.authService.token$.pipe(
        filter(notNull),
        switchMap((token) => {
          this.token = token;
          return this.setTokenAndGo(req, next);
        })
      );
    }
  }

  private setTokenAndGo(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = req.headers.set('Authorization', `Bearer ${this.token}`);
    const requestClone = req.clone({ headers });
    return next.handle(requestClone);
  }
}
