<div *ngIf="isLoading" class="text-xs-center d-flex flex-column align-items-center justify-content-center h-100 w-100">
  <ng-container *ngIf="!withSkeleton">
    <i class="fas fa-cog fa-spin"></i>
    <span class="mt-2" *ngIf="loadingMessage">{{ loadingMessage }}</span>
  </ng-container>

  <ng-container *ngIf="withSkeleton">
    <ng-content select="[skeleton]"></ng-content>
  </ng-container>
</div>
<span class="small d-inline-block w-100 text-center" *ngIf="showError">
  {{ fallbackMessage }}
</span>
<ng-content select="[content]" *ngIf="!isLoading && !showError"></ng-content>
