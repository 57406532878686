import { fromUTC, toUTC } from '@app/@shared/date-util-functions';
import * as UAParser from 'ua-parser-js';
import { IAddress } from '../address.model';
import { Customer, ICustomer } from '../customer/customer.model';
import { Device, IDevice } from '../device.model';
import { Discount, IDiscount } from '../discount/discount.model';
import { IFee } from '../fee.model';
import { IItem } from '../item/item.model';
import { CustomerVehicle } from '../vehicle.model';
import { IZone } from '../zone.model';
import { CheckDiscount, CheckSelection, OrderCheck } from './order-check.model';
import { StoreUtils } from '../company/store-utils.model';
import * as moment from 'moment-timezone';
import { IPizzaTopping } from '../pizza-topping.model';
import { PizzaSide } from '@app/screens/item-drawer/components/pizza-side-selector.component';
import { IDeliveryDetails } from '../delivery-details.model';
import { isAddressInsideDeliveryZone } from '@app/@shared/util-functions';
import { IAppliedServiceCharge } from '../applied-service-charge.model';

export interface IOrder {
  id: string;
  IdempotencyKey?: string;
  pos_order_id: string;
  order_type: OrderType;
  is_asap: boolean;
  scheduled_time: string;
  date_asked: string;
  store_name: string;
  subtotal: number;
  tax: number;
  delivery_fee: number;
  user_delivery_fee: number;
  tax_rate: string;
  discounts: number;
  resp_messages?: string[];
  total: number;
  pending_payment: boolean;
  tip: number;
  items?: IItem[];
  OrderStatus: OrderStatus;
  status: OrderStatus;
  StatusHistory?: StatusHistory;
  notes: string;
  address: IAddress | null;
  store_address: IAddress | null;
  customer: Customer | null;
  checks?: Array<OrderCheck>;
  is_favorite: boolean;
  appliedDiscounts: Discount[] | null;
  party_table: string | null;
  vehicle?: CustomerVehicle;
  device?: IDevice | null;
  DeliveryDetails?: IDeliveryDetails | null;
  CompanyGroupId?: string | null;
  ParentOrderId?: string | null;
}

export interface IOrderFunction {
  calculateOrder: () => void;
  isOrderTypeAvailable: (
    option: OrderType,
    storeUtils: StoreUtils,
    order: IOrder
  ) => { available: boolean; asapAndDateAsked: Partial<Order> };
  clearOrder: () => void;
  addItem: (item: IItem, toggleFavoriteOnAddItem?: boolean) => void;
  toggleFavorite: (discount: IDiscount) => void;
  setCurrentOrderType: (order_type: OrderType, customer?: ICustomer, zones?: IZone[]) => void;
  setDeliveryServiceCharges: (zones?: IZone[]) => void;
  removeDeliveryServiceCharges: () => void;
  deleteItem: (item_id: string, idx: number) => void;
  applyDiscount: (discount?: IDiscount) => void;
  removeDiscount: (discount?: IDiscount) => void;
  removeDiscounts: () => void;
  setDevice: () => void;
  setTableNumber: (party_table: string) => void;
  setTipAmount: (tip: number) => void;
  setVehicle: (vehicle?: CustomerVehicle) => void;
  setCustomer: (customer?: ICustomer) => void;
  getOrderDiscount: () => number;
  getCheckDiscounts: () => void;
  getDevice: () => void;
  getServiceCharges: () => void;
  getOrderTax: () => number;
  getSelectionWithDiscount: (order: Order) => CheckSelection[];
  setPendingPayment: (pendingPayment: boolean) => void;
}

export class Order implements IOrder, IOrderFunction {
  private _items: IItem[];
  private _status: OrderStatus;

  public id: string;
  public IdempotencyKey?: string;
  public pos_order_id: string;
  public order_type: OrderType;
  public is_asap: boolean;
  public scheduled_time: string;
  public date_asked: string;
  public store_name: string;
  public subtotal: number;
  public tax: number;
  public delivery_fee: number = 0;
  public user_delivery_fee: number = 0;
  public tax_rate: string;
  public discounts: number;
  public resp_messages?: string[];
  public total: number;
  public pending_payment: boolean;
  public tip: number;
  public set items(_items: IItem[]) {
    this._items = _items;
    if (this.checks) {
      this.checks = [
        {
          ...this.checks[0],
          Selections: this.getSelectionWithDiscount(this),
        },
      ];
    }
  }
  public get items() {
    return this._items ?? this.checks?.[0].Selections ?? [];
  }
  public set status(_status: OrderStatus) {
    this._status = _status;
  }
  public get status() {
    return this._status ?? this.OrderStatus;
  }
  public StatusHistory: StatusHistory;
  public OrderStatus: OrderStatus;
  public notes: string;
  public address: IAddress | null;
  public customer: Customer | null;
  public checks?: Array<OrderCheck>;
  public is_favorite: boolean;
  public appliedDiscounts: Discount[] | null;
  public party_table: string | null;
  public vehicle?: CustomerVehicle;
  public device?: IDevice | null;
  public DeliveryDetails?: IDeliveryDetails | null;
  public store_address: IAddress | null;
  public CompanyGroupId?: string | null;
  public ParentOrderId?: string | null;

  constructor(obj: IOrder) {
    Object.entries(obj).forEach(([key, value]) => Object.assign(this, { [key]: value }));
    if (obj.checks?.[0]?.Selections?.length) {
      this._items = obj.checks[0].Selections;
    } else if (obj.items?.length) {
      this._items = obj.items;
    } else if ((obj as Order)._items?.length) {
      this._items = (obj as Order)._items;
    } else {
      this._items = [];
    }
    this._status = obj.OrderStatus ?? obj.status ?? OrderStatus.Created;
    if (this.checks?.[0]?.Customer) {
      const checkCustomer = this.checks[0].Customer;
      this.customer = checkCustomer as unknown as Customer;
      if (this.customer) {
        const first_name = this.customer.FirstName ?? '';
        const last_name = this.customer.LastName ? ' ' + this.customer.LastName : '';
        this.customer.Name = this.customer.Name ?? `${first_name}${last_name}`;
      }
      Object.entries(checkCustomer).forEach(([key, value]) => {
        if (!this.customer) this.customer = {} as Customer;
        if (key in this.customer) {
          (this.customer as any)[key] = value;
        } else if (this.customer.ExtendedData) {
          this.customer.ExtendedData[key] = value;
        }
      });
    }
  }

  public calculateOrder() {
    return (
      new Order({
        ...this,
        tax: this.getOrderTax(),
        discounts: this.getOrderDiscount(),
        subtotal: this.getOrderSubTotal(),
        total: this.getOrderSubTotal() + this.getOrderTax() - this.getOrderDiscount(),
      }) ?? null
    );
  }

  public isOrderTypeAvailable(
    option: OrderType,
    storeUtils: StoreUtils,
    order: IOrder
  ): { available: boolean; asapAndDateAsked: Partial<Order> } {
    let available = false;
    const asap = storeUtils.getAsapAvailable(option, order);
    const scheduled = storeUtils.getActiveDays(option);
    const asapAndDateAsked: Partial<Order> = {};

    if (order.is_asap && asap.available) {
      available = true;
      asapAndDateAsked.is_asap = true;
      asapAndDateAsked.date_asked = asapAndDateAsked.scheduled_time = moment
        .utc()
        .add(asap.prepTime, 'minutes')
        .format();
    } else if (scheduled.length > 0) {
      available = true;
      asapAndDateAsked.is_asap = false;
      let response = storeUtils.getValidTimes(fromUTC(storeUtils.tzOffset, order.date_asked), option, this);
      const currentDate = fromUTC(storeUtils.tzOffset, order.date_asked).format('hh:mm A');

      if (response.validTimes?.includes(currentDate)) {
        asapAndDateAsked.date_asked = asapAndDateAsked.scheduled_time = order.date_asked;
      } else {
        const minDate = fromUTC(storeUtils.tzOffset, moment.utc());
        response = storeUtils.getValidTimes(minDate, option, this);
        const validTimes = response.validTimes;
        if (validTimes) {
          asapAndDateAsked.date_asked = asapAndDateAsked.scheduled_time = toUTC(
            storeUtils.tzOffset,
            minDate.format('YYYY-MM-DD') + ' ' + validTimes[0],
            'YYYY-MM-DD hh:mm A'
          ).format();
        }
      }
    }
    if (available) {
      asapAndDateAsked.order_type = option;
      this.order_type = option;
    }
    return { available, asapAndDateAsked };
  }

  public clearOrder() {
    this.items = [];
    this.delivery_fee = 0;
    if (this.checks?.[0]?.AppliedServiceCharges?.[0]) {
      this.checks[0].AppliedServiceCharges[0].ChargeAmount = 0;
    }
    console.log('clearOrder', this.checks?.[0].Selections);
    if (this.checks?.[0].Selections) {
      this.checks[0].Selections = [];
    }
  }

  public addItem(item: IItem, toggleFavoriteOnAddItem?: boolean) {
    if (toggleFavoriteOnAddItem) {
      this.is_favorite = false;
    }
    this._items.push(item);
    return true;
  }

  public deleteItem(item_id: string, idx: number) {
    this.items = this.items.filter((item: IItem, index: number) => !(item.item_id === item_id && index === idx));
    this.appliedDiscounts?.forEach((appliedDiscount: IDiscount) => {
      if (appliedDiscount && appliedDiscount.applied_type === 'ITEM') {
        const discountItems = appliedDiscount.items;
        const orderHasItemWithDiscount = this.items.some((item: IItem) => discountItems.includes(item.item_id));
        if (!orderHasItemWithDiscount) {
          this.appliedDiscounts?.splice(
            this.appliedDiscounts?.findIndex((disc: IDiscount) => disc.guid === appliedDiscount.guid),
            1
          );
        }
      }
    });
  }

  public applyDiscount(discount?: IDiscount) {
    if (discount?.guid) {
      if (discount.disable_other_discounts) {
        this.appliedDiscounts?.splice(0, this.appliedDiscounts?.length);
      }
      this.appliedDiscounts?.push(discount);
    }
  }

  public removeDiscount(discount?: IDiscount) {
    if (discount?.guid) {
      this.appliedDiscounts?.splice(
        this.appliedDiscounts?.findIndex((disc: Discount) => disc.guid === discount.guid),
        1
      );
    }
  }

  public removeDiscounts() {
    if (this.appliedDiscounts) {
      while (this.appliedDiscounts.length) {
        this.appliedDiscounts.pop();
      }
    }
  }

  public setCurrentOrderType(order_type: OrderType, customer?: ICustomer, zones?: IZone[]) {
    this.order_type = order_type;
    if (order_type === OrderType.DELIVERY) {
      const isAddressSaved = (add: IAddress) => {
        let addresses: IAddress[] = [];
        if (customer) {
          addresses = customer.Addresses ?? [];
        } else {
          addresses = JSON.parse(sessionStorage.getItem('LOCAL-ADDRESSES') ?? '[]');
        }
        return !!addresses.find((a) => a.line1 === add.line1 && a.line2 === add.line2 && a.notes === add.notes);
      };
      if (!(this.address && isAddressSaved(this.address))) {
        if (customer) {
          this.address = customer.Addresses?.[0] ?? null;
        } else {
          const localSavedAddresses = JSON.parse(sessionStorage.getItem('LOCAL-ADDRESSES') ?? '[]');
          this.address = localSavedAddresses?.[0] ?? null;
        }
      }
      this.setDeliveryServiceCharges(zones);
    } else {
      this.removeDeliveryServiceCharges();
      this.address = null;
    }
  }

  public setPendingPayment(pendingPayment: boolean) {
    this.pending_payment = pendingPayment;
  }

  // @TODO CHANGE THIS TO USE DOORDASH FEE
  public setDeliveryServiceCharges(zones?: IZone[]) {
    if (zones && zones.length > 0 && this.address) {
      const address = this.address;
      const matchedZones = zones
        .filter((zone) => !zone.is_disabled)
        .filter((zone) => isAddressInsideDeliveryZone(address, zone));
      if (matchedZones && matchedZones.length > 0) {
        let AppliedServiceCharges: OrderCheck['AppliedServiceCharges'] = null;
        const subtotal = this.getOrderSubTotal();
        const lessExpensiveFee = matchedZones
          .map((zone) => {
            return zone.fees
              .filter((fee) => !fee.is_disabled)
              .find((fee) => subtotal >= fee.min_amount && subtotal < fee.threshold_amount);
          })
          .filter((fee): fee is IFee => fee !== undefined)
          .reduce<IFee | undefined>((prev, curr) => {
            if (!prev || prev.fee_amount > curr.fee_amount) {
              return curr;
            }
            return prev;
          }, undefined);

        if (lessExpensiveFee) {
          AppliedServiceCharges = [
            {
              ServiceCharge: { Guid: lessExpensiveFee.guid },
              ChargeAmount: 0,
            },
          ];
        }
        this.delivery_fee =
          this.checks?.[0]?.AppliedServiceCharges?.reduce((total, charge) => total + charge.ChargeAmount, 0) ?? 0;
        this.checks = [
          {
            ...(this.checks ? this.checks[0] : ({} as OrderCheck)),
            AppliedServiceCharges,
          },
        ];
      }
    }
  }

  // @TODO CHANGE THIS TO REMOVE DOORDASH FEE
  public removeDeliveryServiceCharges() {
    this.checks = [
      {
        ...(this.checks ? this.checks[0] : ({} as OrderCheck)),
        AppliedServiceCharges: null,
      },
    ];
  }

  public updateDeliveryServiceCharges(serviceCharge: IAppliedServiceCharge) {
    let aux = this.checks?.[0]?.AppliedServiceCharges ?? null;
    if (aux) {
      aux = aux.map((charge) => {
        if (charge?.ServiceCharge?.Guid === serviceCharge?.ServiceCharge?.Guid) {
          charge.ChargeAmount = serviceCharge.ChargeAmount;
        }
        return charge;
      });
    }
    this.checks = [
      {
        ...(this.checks ? this.checks[0] : ({} as OrderCheck)),
        AppliedServiceCharges: aux,
      },
    ];
  }

  public toggleFavorite() {
    this.is_favorite = !this.is_favorite;
  }

  public getOrderSubTotal(): number {
    let total = 0;
    this.items.forEach((item: IItem) => {
      const itemTotal = this.getItemTotal(item);
      total += itemTotal * item.quantity;
    });
    return total ?? 0;
  }

  public getOrderTax(): number {
    let tax = 0;
    if (this.tax_rate && parseFloat(this.tax_rate) > 0) {
      return this.getOrderSubTotal() * (parseFloat(this.tax_rate) / 100);
    } else {
      this.items
        .filter((item: IItem) => item.is_taxable)
        .forEach((item: IItem) => {
          const itemTotal = this.getItemTotal(item) * item.quantity;
          tax += itemTotal * (item.tax_rate / 100);
        });
    }
    return tax;
  }

  public getOrderDiscount(): number {
    let discount = 0;
    if (this.items && this.appliedDiscounts) {
      this.appliedDiscounts?.forEach((appliedDiscount: Discount) => {
        if (appliedDiscount.type === 'AMOUNT') {
          discount += appliedDiscount.amount;
        } else if (appliedDiscount.type === 'PERCENT') {
          let value: number | undefined | null = 0;
          if (appliedDiscount.applied_type === 'ORDER') {
            value = this.getOrderSubTotal();
          } else if (appliedDiscount.applied_type === 'ITEM') {
            value = this.items.reduce(
              (acc: number, curr: IItem) =>
                appliedDiscount.items.includes(curr.item_id) ? acc + this.getItemTotal(curr) * curr.quantity : acc,
              0
            );
          }
          discount += value ? value * (appliedDiscount.amount / 100) : discount;
        }
      });
    }
    return Math.min(this.getOrderSubTotal(), discount) ?? 0;
  }

  public getSelectionWithDiscount(order: Order) {
    const selection = order.items.map(
      (item: IItem) =>
        ({
          ...item,
          AppliedDiscounts: this.appliedDiscounts
            ?.filter(
              (discount: Discount) => discount?.items?.includes(item.item_id) && discount.applied_type === 'ITEM'
            )
            .map((disc: { guid: any }) => ({
              Discount: {
                Guid: disc.guid,
              },
            })),
        } as CheckSelection)
    );
    return selection;
  }

  public setTipAmount(tip: number) {
    this.tip = tip;
  }

  public setCustomer(customer?: ICustomer) {
    if (this.items && customer) {
      this.customer = new Customer(customer);
    }
  }

  public setTableNumber(party_table: string) {
    if (this.items && party_table) {
      this.party_table = party_table;
      this.order_type = OrderType.DINE_IN;
    }
  }

  public setVehicle(vehicle?: CustomerVehicle) {
    if (this.items && vehicle) {
      this.vehicle = vehicle;
      this.order_type = OrderType.CURBSIDE;
    }
  }

  public setDevice() {
    this.device = this.getDevice();
  }

  public setAddress(address?: IAddress) {
    if (this.items && address) {
      this.address = address;
      this.order_type = OrderType.DELIVERY;
    }
  }

  public getCheckDiscounts(store_id?: string, company_id?: string) {
    const checksDiscounts: { Discount: CheckDiscount; store_id: string; company_id: string }[] = [];
    this.appliedDiscounts?.forEach((appliedDiscount: Discount) => {
      if (appliedDiscount.applied_type === 'ORDER') {
        checksDiscounts.push({
          Discount: {
            Guid: appliedDiscount.guid,
          } as any,
          store_id: (appliedDiscount as any).store_id ?? store_id,
          company_id: (appliedDiscount as any).company_id ?? company_id,
        });
      }
    });
    const ret = checksDiscounts ? checksDiscounts : [];
    return ret;
  }

  public getServiceCharges() {
    // @TODO GET FROM WHERE WE'LL SAVE THE DOORDASH FEE
    let response =
      this.checks?.[0]?.AppliedServiceCharges?.reduce((total, charge) => total + charge.ChargeAmount, 0) ?? null;
    return response !== null ? response : null;
  }

  public getDevice() {
    const parser = new UAParser();
    const { model, vendor, type } = parser.getDevice();
    const { name: OSName, version: OSVersion } = parser.getOS();
    const { name: browserName, version: browserVersion } = parser.getBrowser();
    return new Device({
      model: model ?? '',
      vendor: vendor ?? '',
      type: type ?? 'desktop',
      browserName: browserName ?? '',
      browserVersion: browserVersion ?? '',
      OSName: OSName ?? '',
      OSVersion: OSVersion ?? '',
    });
  }

  public getItemPrice(item: IItem) {
    let isPizzaTopping: boolean;
    let pizzaTopping: IPizzaTopping;
    let price: number;

    const items = this.items.reduce((acc, curr) => [...acc, curr, ...curr.modifiers], [] as IItem[]);
    const parentModifierOption = items.find((mod) => mod.id === item.parent_item_id);
    const parentModifierOptionOverrides =
      item.id && parentModifierOption
        ? parentModifierOption.modifier_groups_overrides?.[item.group_id]?.[item.id]
        : undefined;
    if (parentModifierOption && parentModifierOptionOverrides) {
      isPizzaTopping = parentModifierOptionOverrides.is_pizza_topping;
      pizzaTopping = parentModifierOptionOverrides.pizza_topping;
      price = parentModifierOptionOverrides.system_price;
    } else {
      isPizzaTopping = item.is_pizza_topping;
      pizzaTopping = item.pizza_topping;
      price = item.system_price;
    }

    if (isPizzaTopping) {
      return item.pizza_side === PizzaSide.WHOLE ? pizzaTopping.whole_price : pizzaTopping.side_price;
    }
    return price;
  }

  public getLastPayment() {
    if (this.checks && this.checks.length > 0) {
      const check = this.checks[0];
      const paymentsLength = check?.Payments?.length ?? -1;
      if (paymentsLength > -1) {
        return this.checks[0].Payments[paymentsLength - 1];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public isInAValidStatus() {
    // All errors generate a status greater than 400
    return this.status < OrderStatus.ProcessingFailure;
  }

  public isAValidOpenOrder() {
    // Valid orders that doesn't have any errors have a 'Created' status
    return this.status === OrderStatus.Created;
  }

  public isFinished() {
    return this.status === OrderStatus.Finished;
  }

  public isCancelled() {
    return this.status === OrderStatus.Cancelled;
  }

  public isCompleted() {
    return this.status >= OrderStatus.Finished;
  }

  public allowEdit() {
    return this.status < OrderStatus.Confirmed;
  }

  private getItemTotal(item: IItem) {
    let total = this.getItemPrice(item);
    if (Array.isArray(item.modifiers)) {
      item.modifiers.forEach((mod) => {
        total += this.getItemPrice(mod) * mod.quantity;
      });
    }
    return total;
  }
}
export class GuestEditOrderObject {
  order: Order;
  token: string;
}

export enum OrderType {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
  DINE_IN = 'DINE_IN',
  CURBSIDE = 'CURBSIDE',
  CATERING = 'CATERING',
  OTHER = 'OTHER',
}

// Definition of all status in a flow of ordering
// We have 6 groups of substatus in a context of
//  - Pre processing (All)
//  - Processing   (All)
//  - In DeliveryProcess (Only when Delivery)
//  - Complete
//  - Delivery Fail (Only When Delivery)
//  - Cancellation
export enum OrderStatus {
  // Pre processing
  Created = 100,
  Enqueued = 101,
  SentToPos = 102,
  WaitingForApproval = 103,

  // Processing
  Confirmed = 150,
  Ready = 151,

  // In Delivery Process
  DriverPickedUp = 180,
  DriverConfirmedConsumerArrival = 181,

  // Complete
  Finished = 200,

  // Process Failed
  ProcessingFailure = 400,

  // Delivery Fail
  DeliveryCancelled = 480,
  DeliveryAttempted = 481,

  // Cancellation
  CancellationRequested = 500,
  CancellationApproved = 501,
  Cancelled = 550,
}

export type StatusHistory = {
  [key in OrderStatus]: string;
};
