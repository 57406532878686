import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';

export const tabC11nMetadata: CustomizableGroup = {
  name: 'tab',
  title: 'Tab',
  items: [
    {
      name: 'containerBackgroundColor',
      title: 'Container',
      type: CustomizableItemType.COLOR,
      cssVarName: 'tabContainerBackgroundColor',
    },
    {
      name: 'activeItemBackgroundColor',
      title: 'Active Item',
      type: CustomizableItemType.COLOR,
      cssVarName: 'tabActiveItemBackgroundColor',
    },
    {
      name: 'inactiveItemBackgroundColor',
      title: 'Inactive Item',
      type: CustomizableItemType.COLOR,
      cssVarName: 'tabInactiveItemBackgroundColor',
    },
    {
      name: 'cssActive',
      title: 'Custom CSS: Active Tab',
      type: CustomizableItemType.CSS,
    },
    {
      name: 'cssInactive',
      title: 'Custom CSS: Inactive Tab',
      type: CustomizableItemType.CSS,
    },
  ],
};

export const defaultTabConfig = {
  containerBackgroundColor: '#000',
  activeItemBackgroundColor: '#000',
  inactiveItemBackgroundColor: '#000',
  cssActive: `
      border-bottom: 2px solid #fff;
    `,
  cssInactive: `
      border-bottom: 2px solid transparent;
    `,
};
