import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { throwIfEmpty } from 'rxjs/operators';
import { EventTypeEnum } from '../models/events/EventTypeEnum';
import { Order } from '../models/order/order.model';
import { Response, ResponseObj } from '../models/response.model';
import { describeUserAgent, getUserIpAndLocation } from 'src/argus/utils';
import { SessionService } from './session.service';
import { Logger } from './logger.service';

const log = new Logger('EventService');

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  constructor(private http: HttpClient, private sessionService: SessionService) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private subs: Subscription = new Subscription();

  async SaveEvent(company: string, event: EventTypeEnum, message: string, context: {} = {}) {
    var user = await this.getUser();
    var session = this.sessionService.Session;
    this.subs.add(
      this.http
        .post<any>(`Analytics/events`, {
          Type: event,
          Message: message,
          Contexts: context,
          CompanyKey: company,
          User: user,
          SessionId: session?.SessionId,
          SessionStartTime: session?.SessionStartTime,
        })
        .subscribe(
          (data) => {
            if (data) {
              this.sessionService.ClearSession();
              log.info('New session ig generated ' + data.SessionId);
              this.sessionService.Session = data;
            }
          },
          (err) => {
            console.log(err);
          }
        )
    );
  }

  private async getUser() {
    const { ip, location } = await getUserIpAndLocation();
    let user = {
      host: window.location.hostname,
      path: window.location.pathname,
      device: {
        user_agent: describeUserAgent(),
        window_width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        window_height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
      },
      location: location,
      referring_url: document.referrer,
      ip_address: ip,
    };
    return user;
  }
}
