// tslint:disable: member-ordering
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { ControledByMaestro } from './maestro.service';
import { Logger } from './logger.service';
import { CompanyService } from './company.service';
import { OrderService } from './order.service';
import { filter } from 'rxjs/operators';
import { IItem } from '@app/@core/models/item/item.model';
import { notNull } from '@app/@shared/util-functions';
import { Discount, DiscountWithItems } from '../models/discount/discount.model';

const logger = new Logger('DiscountService');

@Injectable({
  providedIn: 'root',
})
export class DiscountService implements ControledByMaestro, OnDestroy {
  constructor(private http: HttpClient, private companyService: CompanyService, private orderService: OrderService) {}

  private readonly _discountList = new BehaviorSubject<Discount[] | null>(null);
  private readonly _discountListWithItems = new BehaviorSubject<DiscountWithItems[] | null>(null);

  private subs: Subscription = new Subscription();

  public readonly discountList$ = this._discountList.asObservable();

  public get discountList() {
    return this._discountList.getValue();
  }
  public get discountListWithItems() {
    return this._discountListWithItems.getValue();
  }
  public discountsWithItems: DiscountWithItems[] = [];

  public get items(): IItem[] {
    return this.companyService.currentMenu ? this.companyService.currentMenu.items : [];
  }

  public init() {
    logger.info('Init');
    this.subs.add(
      combineLatest([
        this.companyService.currentMenu$.pipe(filter(notNull)),
        this.discountList$.pipe(filter(notNull)),
      ]).subscribe(() => {
        this.populateDiscountListItems();
      })
    );
    this.subs.add(
      this.getDiscountList()
        .pipe(filter(notNull))
        .subscribe((discountList) => {
          logger.info('Discount List fetched');
          this._discountList.next(discountList);
        })
    );
  }

  populateDiscountListItems() {
    this.discountsWithItems = [];
    this.discountList?.forEach((discount: Discount) => {
      const auxItems: IItem[] = [];
      if (discount.items.length > 0) {
        discount.items.forEach((item) => {
          const auxTeste = this.items?.find((x) => x.id === item);
          auxItems.push(auxTeste!);
        });
      }
      this.discountsWithItems.push({ ...discount, items: auxItems });
    });
    this._discountListWithItems.next(this.discountsWithItems);
    return this.discountsWithItems;
  }

  convertDiscount(discount: Discount): DiscountWithItems {
    const auxItems: IItem[] = [];
    if (discount?.items.length > 0) {
      discount.items.forEach((item) => {
        const auxTeste = this.items?.find((x) => x.id === item);
        auxItems.push(auxTeste!);
      });
    }
    return { ...discount, items: auxItems };
  }

  private getDiscountList() {
    return this.http.get<Discount[]>(`discount/${this.companyService.currentStore?.id}`);
  }

  public checkDiscount(discountCode: string) {
    return this.http.get<Discount>(`discount/${this.companyService.currentStore?.id}/checkdiscount/${discountCode}`);
  }

  public getAvailableDiscounts() {
    const available: Discount[] = [];
    if (this.orderService.currentOrder) {
      this.discountList?.forEach((discount) => {
        if (discount?.applied_type === 'ORDER' || (discount?.applied_type === 'ITEM' && discount?.items.length > 0)) {
          available.push(discount);
        }
      });
    }
    return available;
  }

  public getOrderDiscounts() {
    return this.orderService.currentOrder?.appliedDiscounts ?? [];
  }

  public checkItemsDiscounts(item_id: string) {
    const appliedDiscounts = this.orderService.currentOrder?.appliedDiscounts;
    appliedDiscounts?.forEach((appliedDiscount: Discount) => {
      const auxItem = appliedDiscount?.items?.some((x: any) => x.id === item_id);
      if (
        auxItem &&
        (appliedDiscount?.applied_type === 'ORDER' ||
          (appliedDiscount?.applied_type === 'ITEM' && appliedDiscount?.items.length > 0))
      ) {
        this.orderService.applyDiscount(undefined);
      }
    });
  }

  isDisabled(discount: DiscountWithItems) {
    let isValid = true;
    if (discount.applied_type === 'ITEM' && discount.max_usage >= 1) {
      const discountItemsIds = discount.items.map((discountItem) => discountItem.id);
      const orderItemsIds = this.orderService.currentOrder?.items.map((orderItem: IItem) => orderItem.item_id);
      isValid = orderItemsIds?.some((orderItem: string) => discountItemsIds.includes(orderItem)) ?? false;
    }
    return !isValid;
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
