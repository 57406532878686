export class IDevice {
  model: string;
  type: string;
  vendor: string;
  browserName: string;
  browserVersion: string;
  OSName: string;
  OSVersion: string;
}

export class Device implements IDevice {
  model: string;
  type: string;
  vendor: string;
  browserName: string;
  browserVersion: string;
  OSName: string;
  OSVersion: string;

  constructor(obj: IDevice) {
    Object.entries(obj).forEach(([key, value]) => Object.assign(this, { [key]: value }));
  }
}
