import { CSS } from '@app/@core/theme/model/theme-config';
import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';

export interface StoreCardThemeConfig {
  storeCard: {
    template: 'with-header' | 'button-at-right' | 'button-at-bottom';
    css?: CSS;
  };
}
export const defaultStoreCardThemeConfig: StoreCardThemeConfig = {
  storeCard: {
    template: 'with-header',
  },
};
export const storeCardC11nMetadata: CustomizableGroup = {
  title: 'Store Card',
  name: 'storeCard',
  items: [
    {
      title: 'Template',
      name: 'template',
      type: CustomizableItemType.SELECT,
      options: [
        {
          label: 'With Header',
          value: 'with-header',
        },
        {
          label: 'Button at Right',
          value: 'button-at-right',
        },
        {
          label: 'Button at Bottom',
          value: 'button-at-bottom',
        },
      ],
    },
  ],
};
