import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { notNull, pipeFromArray } from '@app/@shared/util-functions';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { IGuestCustomerInfo } from '../models/customer/guest-customer-info.model';
import { EventTypeEnum } from '../models/events/EventTypeEnum';
import { CompanyService } from './company.service';
import { EventService } from './event.service';
import { ControledByMaestro } from './maestro.service';

const STORAGE_KEYS = {
  TOKEN_KEY: 'APP_TOKEN',
  GUEST_KEY: 'APP_IS_GUEST',
  GUEST_INFO: 'GUEST_INFO',
  REFRESH_KEY: 'REFRESH_KEY',
  TOKEN_EXP_KEY: 'TOKEN_EXP_KEY',
};

export interface LoginRequestDTO {
  Email: string;
  Password: string;
}

export interface SignupRequestDTO {
  Code: string;
  Name: string;
  Email: string;
  Phone: string;
  Password: string;
  CompanyKey: string;
}

export type AuthResponse =
  | {
      Token: null;
      error: true;
      message: string;
    }
  | {
      Token: string;
      error: false;
    };

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy, ControledByMaestro {
  constructor(
    private router: Router,
    private http: HttpClient,
    private companyService: CompanyService,
    private traceEventService: EventService
  ) {}

  private subs = new Subscription();
  private readonly _token = new BehaviorSubject<string | null>(null);
  private readonly _authenticating = new BehaviorSubject<boolean>(false);
  private readonly _authenticatedAsGuest = new BehaviorSubject<boolean>(false);

  public readonly token$ = this._token.asObservable();
  public readonly authenticating$ = this._authenticating.asObservable();
  public readonly authenticatedAsGuest$ = this._authenticatedAsGuest.asObservable();
  public guestInfo?: IGuestCustomerInfo;

  public init() {
    const isGuest = localStorage.getItem(STORAGE_KEYS.GUEST_KEY);
    const token = window[isGuest ? 'sessionStorage' : 'localStorage'].getItem(STORAGE_KEYS.TOKEN_KEY);
    if (token) {
      this.authenticate(token, !!isGuest);
      if (isGuest) {
        this._authenticatedAsGuest.next(true);
        const _guestInfo = sessionStorage.getItem(STORAGE_KEYS.GUEST_INFO);
        try {
          if (_guestInfo) {
            const guestInfo = JSON.parse(_guestInfo);
            this.setGuestInfo(guestInfo, token);
          }
        } catch {}
      }
    }
  }

  public openLogin(redirect?: string) {
    this.router.navigate([{ outlets: { modal: 'sign-up' } }], {
      queryParams: { redirect: redirect ?? this.router.url },
    });
  }

  public openRecoverPassword() {
    this.router.navigate([{ outlets: { modal: 'recover-password' } }], { queryParamsHandling: 'merge' });
  }

  public login(payload: LoginRequestDTO): Observable<AuthResponse> {
    this._authenticating.next(true);
    return this.http.post<AuthResponse>('auth/sign-in', payload).pipe(this.pipeAuth());
  }

  public signup(payload: SignupRequestDTO): Observable<AuthResponse> {
    this._authenticating.next(true);
    return this.http.post<AuthResponse>('auth/sign-up', payload).pipe(this.pipeAuth());
  }

  public logout() {
    this.unauthenticate();
  }

  public isAdmin() {
    return this.token$.pipe(
      switchMap((token) =>
        this.companyService.currentCompany$.pipe(
          filter(notNull),
          map(({ company_id }) => {
            if (token) {
              const { extension_UserMap } = jwtDecode<{ extension_UserMap: string }>(token);
              if (extension_UserMap) {
                try {
                  const userMap = JSON.parse(extension_UserMap);
                  if (userMap[company_id]) {
                    return true;
                  }
                } catch {
                  return false;
                }
              } else {
                return false;
              }
            }
            return false;
          })
        )
      )
    );
  }

  public isAuthenticated(): this is { token: string } {
    return !!this._token.getValue();
  }

  public setGuestInfo(guestInfo: IGuestCustomerInfo, token: string) {
    localStorage.setItem(STORAGE_KEYS.GUEST_KEY, 'true');
    sessionStorage.setItem(STORAGE_KEYS.GUEST_INFO, JSON.stringify(guestInfo));
    this._authenticatedAsGuest.next(true);
    this.guestInfo = guestInfo;
    this.authenticate(token, true);
  }

  public removeGuestInfo() {
    sessionStorage.removeItem(STORAGE_KEYS.REFRESH_KEY);
    sessionStorage.removeItem(STORAGE_KEYS.TOKEN_EXP_KEY);
    sessionStorage.removeItem(STORAGE_KEYS.GUEST_INFO);
    localStorage.removeItem(STORAGE_KEYS.GUEST_KEY);
    localStorage.removeItem('LOCAL-ADDRESSES');
    localStorage.removeItem('APP_IS_GUEST');
    // localStorage.removeItem('bbweb-guest-checkout');
    this._authenticatedAsGuest.next(false);
    this.guestInfo = undefined;
    this.unauthenticateNoRedirect(true);
  }

  public guestLogin({ name, email, phone, saveInfo }: IGuestCustomerInfo) {
    return this.http
      .post<{ Token: string; RefreshToken: string }>('guest/auth', {
        Name: name,
        Email: email,
        Phone: phone,
        CompanyKey: this.companyService.currentCompany?.company_id,
      })
      .pipe(
        map(({ Token, RefreshToken }) => {
          this.setGuestInfo(
            {
              name,
              email,
              phone,
              saveInfo,
            },
            Token
          );
          this.traceEventService.SaveEvent(
            this.companyService.currentCompany?.company_id as string,
            EventTypeEnum.USER_ACTION,
            'Guest User authenticated'
          );
          sessionStorage.setItem(STORAGE_KEYS.REFRESH_KEY, RefreshToken);
          sessionStorage.setItem(STORAGE_KEYS.TOKEN_EXP_KEY, (new Date().getTime() + 1000 * 60 * 5).toString());
        })
      );
  }

  private pipeAuth() {
    return pipeFromArray<Observable<AuthResponse>, Observable<AuthResponse>>([
      catchError((err) => {
        return of({
          Token: null,
          error: true,
          message: err.error ?? 'We had a problem authenticating you, please try it again later!',
        } as const);
      }),
      map((response) => {
        this._authenticating.next(false);
        if (response.Token === null) {
          return {
            error: true,
            message: response.message,
            Token: null,
          };
        }
        this.authenticate(response.Token);
        this.traceEventService.SaveEvent(
          this.companyService.currentCompany?.company_id as string,
          EventTypeEnum.USER_ACTION,
          'User authenticated'
        );
        return {
          error: false,
          Token: response.Token,
        };
      }),
    ]);
  }

  public unauthenticate(guest: boolean = false) {
    window[guest ? 'sessionStorage' : 'localStorage'].removeItem(STORAGE_KEYS.TOKEN_KEY);
    this._token.next(null);
    window.location.href = location.origin + `/${this.companyService.currentStore?.url_path}/menu`;
  }

  public unauthenticateNoRedirect(guest: boolean = false) {
    window[guest ? 'sessionStorage' : 'localStorage'].removeItem(STORAGE_KEYS.TOKEN_KEY);
    this._token.next(null);
  }

  public authenticate(token: string, guest: boolean = false) {
    window[guest ? 'sessionStorage' : 'localStorage'].setItem(STORAGE_KEYS.TOKEN_KEY, token);
    this._token.next(token);
  }

  public set token(_token: string | null) {
    this._token.next(_token);
  }

  public get token() {
    return this._token.getValue();
  }

  public get authenticating() {
    return this._authenticating.getValue();
  }

  public get authenticatedAsGuest() {
    return this._authenticatedAsGuest.getValue();
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
