<div class="CookiesPopup-backdrop" (click)="closePopup(true)" *ngIf="showPopup"></div>
<div class="CookiesPopup d-flex align-items-center justify-content-between" *ngIf="showPopup">
  <div>
    <p>{{ 'COOKIES.' + 'We only use cookies to provide our services and for analytics and marketing.' | translate }}</p>
    <!-- <p>{{ 'COOKIES.' + 'Click accept for the best user experience on our site.' | translate }}</p> -->
  </div>
  <div class="d-flex align-items-center">
    <button class="btn btn-primary mr-2" (click)="closePopup(true)">{{ 'COOKIES.' + 'Accept' | translate }}</button>
    <button class="btn btn-outline-primary" (click)="closePopup(false)">{{ 'COOKIES.' + 'Deny' | translate }}</button>
  </div>
</div>
