import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CompanyService } from '@app/@core/services/company.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
  public isLoading$: Observable<boolean>;
  public hideHeader: boolean;

  constructor(private router: Router, public route: ActivatedRoute, public companyService: CompanyService) {
    const routesWithoutHeader = [
      '',
      'stores',
      'customize',
      'customize-wizard',
      'company-not-found',
      'admin-login',
      'company-groups',
    ];
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        const path = routerEvent.url.split('?')[0].split('#')[0].split('(')[0].split('/')[1];
        if (routesWithoutHeader.includes(path)) {
          this.hideHeader = true;
        } else {
          this.hideHeader = false;
        }
      }
    });
    const path = this.router.url.split('?')[0].split('#')[0].split('(')[0].split('/')[1];
    if (routesWithoutHeader.includes(path)) {
      this.isLoading$ = of(false);
      this.hideHeader = true;
    } else {
      this.hideHeader = false;
      this.isLoading$ = combineLatest([this.companyService.currentCompany$, this.companyService.currentStore$]).pipe(
        map(([cc, cs]) => cc === null || cs === null)
      );
    }
  }
}
