import { OrderStatus } from '@app/@core/models/order/order.model';
import { UserLocation } from './types';

export const LOCATION_COOKIE_NAME = 'ARGUS_LOCATION_COOKIE_NAME';
export const IP_COOKIE_NAME = 'ARGUS_IP_COOKIE_NAME';

let location: UserLocation = {
  latitude: 0,
  longitude: 0,
  country: '',
  region: '',
  postal: '',
  city: '',
};
let ip: string = '';

export function createUUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function setCookie(name: string, value: string, expireSeconds = 365 * 86400) {
  var exdate = new Date();
  exdate.setSeconds(exdate.getSeconds() + expireSeconds);
  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    ';path=/;expires=' +
    exdate.toUTCString() +
    `;domain=${window.location.hostname}`;
}

export function getCookie(c_name: string) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return decodeURIComponent(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
}

export function describeUserAgent() {
  let describedAgent = '';

  const isMobileDevice = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile|Windows Phone/i);
    },
    any: function () {
      return (
        isMobileDevice.Android() ||
        isMobileDevice.BlackBerry() ||
        isMobileDevice.iOS() ||
        isMobileDevice.Opera() ||
        isMobileDevice.Windows()
      );
    },
  };

  if (isMobileDevice.Android()) {
    describedAgent = 'Mobile Android';
  } else if (isMobileDevice.BlackBerry()) {
    describedAgent = 'Mobile BlackBerry';
  } else if (isMobileDevice.iOS()) {
    describedAgent = 'Mobile iOS';
  } else if (isMobileDevice.Opera()) {
    describedAgent = 'Mobile Opera';
  } else if (isMobileDevice.Windows()) {
    describedAgent = 'Mobile Windows';
  }

  if (describedAgent.length > 0) {
    describedAgent = (describedAgent + ': ' + navigator.userAgent).trim();
  } else {
    describedAgent = navigator.userAgent;
  }

  describedAgent = describedAgent.trim();

  return describedAgent;
}

export async function getUserIpAndLocation(): Promise<{ location: UserLocation; ip: string }> {
  const savedIp = getCookie(IP_COOKIE_NAME);
  const savedLocation = getCookie(LOCATION_COOKIE_NAME);
  if (savedIp && savedLocation) {
    return Promise.resolve({
      location: JSON.parse(savedLocation),
      ip: savedIp,
    });
  }
  await ipinfo();
  return Promise.resolve({
    location,
    ip,
  });
}

function ipinfo() {
  return fetch('https://ipinfo.io/json?token=4a7441f7271fb7')
    .then((response) => response.json())
    .then((ipLocation) => {
      if (typeof ipLocation !== 'undefined' && ipLocation) {
        ip = ipLocation.ip;

        location.country = ipLocation.country;
        location.city = ipLocation.city;
        location.postal = ipLocation.postal;
        location.region = ipLocation.region;
        location.latitude = ipLocation.loc.split(',')[0];
        location.longitude = ipLocation.loc.split(',')[1];
        setCookie(IP_COOKIE_NAME, ip, 60 * 5);
        setCookie(LOCATION_COOKIE_NAME, JSON.stringify(location), 60 * 60 * 24);
      }
    })
    .finally(() => {
      if (!ip || ip.length === 0) {
        return ipapi();
      }
    });
}

function ipapi() {
  return fetch('https://ipapi.co/json/?key=ZhrpmYUZ2FygxcEzw95hkm4uVynvgI0RomuazJffiZtMnMQS5C')
    .then((response) => response.json())
    .then((ipLocation) => {
      if (typeof ipLocation !== 'undefined' && ipLocation) {
        ip = ipLocation.ip;

        location.country = ipLocation.country + ipLocation.country_name;
        location.city = ipLocation.city;
        location.postal = ipLocation.postal;
        location.region = ipLocation.region_code;
        location.latitude = ipLocation.latitude;
        location.longitude = ipLocation.longitude;

        setCookie(IP_COOKIE_NAME, ip, 60 * 5);
        setCookie(LOCATION_COOKIE_NAME, JSON.stringify(location), 60 * 60 * 24);
      }
    })
    .finally(() => {
      if (!ip || ip.length === 0) {
        return whoisxmlapi();
      }
    });
}

function whoisxmlapi() {
  return fetch('https://ip-geolocation.whoisxmlapi.com/api/v1?apiKey=at_4C5qWCHDpIqUpS9tXq8SKHQjijy5S')
    .then((response) => response.json())
    .then((ipLocation) => {
      if (typeof ipLocation !== 'undefined' && ipLocation && ipLocation.location) {
        ip = ipLocation.ip;

        location.country = ipLocation.location.country;
        location.city = ipLocation.location.city;
        location.postal = ipLocation.location.postalCode;
        location.region = ipLocation.location.region;
        location.latitude = ipLocation.location.lat;
        location.longitude = ipLocation.location.lng;

        setCookie(IP_COOKIE_NAME, ip, 60 * 5);
        setCookie(LOCATION_COOKIE_NAME, JSON.stringify(location), 60 * 60 * 24);
      }
    })
    .finally(() => {
      if (!ip || ip.length === 0) {
        return ipify();
      }
    });
}

function ipify() {
  return fetch('//api.ipify.org/?format=json')
    .then((response) => response.json())
    .then((data) => {
      try {
        if (data && data.ip && data.ip.length > 0 && data.ip.length < 20) {
          setCookie(IP_COOKIE_NAME, data.ip, 60 * 1);
          ip = data.ip;
        }
      } catch (e) {
        ip = 'Not found';
      }
    })
    .catch(() => {
      ip = 'Not found';
    });
}
