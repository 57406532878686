import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MultipleTemplateDirective } from '@app/@core/theme/directive/multiple-template.directive';
import { Subscription } from 'rxjs';
import { TemplateFactory } from '../model/multiple-template.factory';
import { ThemeConfig } from '../model/theme-config';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'multiple-template',
  templateUrl: './multiple-template.component.html',
  styleUrls: [],
})
export class MultipleTemplateComponent implements OnInit, OnDestroy {
  @Input() templateFactory: TemplateFactory;
  @Input() useRouteResolver = false;
  @ViewChild(MultipleTemplateDirective, { static: true }) multipleTemplate: MultipleTemplateDirective;
  private subs: Subscription = new Subscription();

  constructor(private themeService: ThemeService, private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy() {
    this.templateFactory?.ngOnDestroy?.();
    this.subs.unsubscribe();
  }

  loadComponent() {
    let theme: ThemeConfig | null;
    if (this.useRouteResolver) {
      let _route = this.route;
      while (_route.firstChild !== null) {
        _route = _route.firstChild;
      }
      // tslint:disable-next-line: no-string-literal
      theme = _route.snapshot.data['theme'];
    } else {
      theme = this.themeService.theme;
    }

    if (theme) {
      this.subs.add(
        this.themeService.theme$.subscribe((_theme) => {
          if (!_theme) _theme = theme as ThemeConfig;
          const component = this.templateFactory.getComponent(_theme as ThemeConfig);

          const viewContainerRef = this.multipleTemplate.viewContainerRef;
          viewContainerRef.clear();

          const ref = viewContainerRef.createComponent(component);
          // Sync Bindings
          this.syncBindings(ref);
        })
      );
    }
  }

  syncBindings(ref: ComponentRef<any>) {
    // Sync Bindings
    if (this.templateFactory.inputs) {
      this.subs.add(
        this.templateFactory.inputs.subscribe((inputs) => {
          Object.entries(inputs).forEach(([key, value]) => {
            ref.instance[key] = value;
          });
          this.cdRef.detectChanges();
        })
      );
    }
    if (this.templateFactory.outputs) {
      this.subs.add(
        this.templateFactory.outputs.subscribe((outputs) => {
          Object.entries(outputs).forEach(([outputKey, eventEmitter]) => {
            this.subs.add(
              ref.instance[outputKey]?.subscribe((value: any) => {
                eventEmitter.emit(value);
              })
            );
            this.cdRef.detectChanges();
          });
        })
      );
    }
  }
}
