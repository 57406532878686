import {
  defaultStorePickerThemeConfig,
  StorePickerThemeConfig,
} from '@app/screens/store-picker/templates/store-picker.model';
import {
  defaultItemDrawerThemeConfig,
  ItemDrawerThemeConfig,
} from '@app/screens/item-drawer/templates/item-drawer.model';
import {
  defaultStoreCardThemeConfig,
  StoreCardThemeConfig,
} from '@app/@shared/components/store-card/templates/store-card.model';
import {
  defaultItemCardThemeConfig,
  ItemCardThemeConfig,
} from '@app/@shared/components/item-card/templates/item-card.model';
import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';
import { defaultTabConfig } from './tab.metadata';
import {
  defaultOrderStatusThemeConfig,
  OrderStatusThemeConfig,
} from '@app/screens/order-status/templates/order-status.model';
import { defaultMenuThemeConfig, MenuThemeConfig } from '@app/screens/menu/templates/menu.model';
import { defaultHeaderThemeConfig, HeaderThemeConfig } from '@app/components/shell/header/templates/header.model';
import {
  CompanyGroupStorePickerThemeConfig,
  defaultCompanyGroupStorePickerThemeConfig,
} from '@app/screens/company-group-store-picker/templates/company-group-store-picker.model';

// Add Screen Configs to this type
type ExtendedConfig = StorePickerThemeConfig &
  StoreCardThemeConfig &
  OrderStatusThemeConfig &
  ItemCardThemeConfig &
  MenuThemeConfig &
  HeaderThemeConfig &
  ItemDrawerThemeConfig &
  CompanyGroupStorePickerThemeConfig;

// Add Screen Config defaults to this variable
const defaultExtendedConfig = {
  ...defaultStorePickerThemeConfig,
  ...defaultStoreCardThemeConfig,
  ...defaultOrderStatusThemeConfig,
  ...defaultItemCardThemeConfig,
  ...defaultMenuThemeConfig,
  ...defaultHeaderThemeConfig,
  ...defaultItemDrawerThemeConfig,
  ...defaultCompanyGroupStorePickerThemeConfig,
};

export interface ThemeConfig extends ExtendedConfig {
  general: {
    primary: string;
    secondary: string;
    white: string;
    black: string;
    light: string;
    dark: string;
    headerColor: string;
  };
  text: {
    color: string;
    colorInverse: string;
    fontFamily: string;
    css?: CSS;
  };
  button: {
    backgroundColor: string;
    textColor: string;
    css?: CSS;
  };
  tab: {
    containerBackgroundColor: string;
    activeItemBackgroundColor: string;
    inactiveItemBackgroundColor: string;
    css?: CSS;
    cssActive?: CSS;
    cssInactive?: CSS;
  };
  logo: string;
}
export type CSS =
  | string
  | {
      base?: string;
      hover?: string;
    };
export const defaultTheme: ThemeConfig = {
  general: {
    primary: '#000',
    secondary: '#000',
    white: '#fff',
    black: '#000',
    light: '#f8f9fa',
    dark: '#343a40',
    headerColor: '#f8f9fa',
  },
  text: {
    color: '#353535',
    colorInverse: '#FFF',
    fontFamily: 'Montserrat',
  },
  button: {
    backgroundColor: '#000',
    textColor: '#FFF',
  },
  logo: '',
  tab: {
    ...defaultTabConfig,
  },
  ...defaultExtendedConfig,
};

export const generalColorsC11nMetadata: CustomizableGroup = {
  name: 'general',
  title: 'Colors',
  items: [
    {
      name: 'primary',
      title: 'Primary',
      type: CustomizableItemType.COLOR,
      cssVarName: 'primary',
    },
    {
      name: 'secondary',
      title: 'Secondary',
      type: CustomizableItemType.COLOR,
      cssVarName: 'secondary',
    },
    {
      name: 'white',
      title: 'White',
      type: CustomizableItemType.COLOR,
      cssVarName: 'white',
    },
    {
      name: 'black',
      title: 'Black',
      type: CustomizableItemType.COLOR,
      cssVarName: 'black',
    },
    {
      name: 'light',
      title: 'Light',
      type: CustomizableItemType.COLOR,
      cssVarName: 'light',
    },
    {
      name: 'dark',
      title: 'Dark',
      type: CustomizableItemType.COLOR,
      cssVarName: 'dark',
    },
    {
      name: 'headerColor',
      title: 'Header',
      type: CustomizableItemType.COLOR,
      cssVarName: 'headerColor',
    },
  ],
};

export const textColorsC11nMetadata: CustomizableGroup = {
  name: 'text',
  title: 'Text',
  items: [
    {
      name: 'color',
      title: 'Main Color',
      type: CustomizableItemType.COLOR,
      cssVarName: 'textColor',
    },
    {
      name: 'colorInverse',
      title: 'Inverse Color',
      type: CustomizableItemType.COLOR,
      cssVarName: 'textColorInverse',
    },
    {
      name: 'fontFamily',
      title: 'Font Family',
      type: CustomizableItemType.FONT,
    },
  ],
};
