import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { ButtonComponent } from './button/button.component';
import { HeaderComponent } from './header/header.component';
import { TextComponent } from './text/text.component';
import { ViewComponent } from './view/view.component';

@NgModule({
  declarations: [HeaderComponent, ButtonComponent, TextComponent, ViewComponent],
  imports: [CommonModule],
  exports: [HeaderComponent, ButtonComponent, TextComponent, ViewComponent],
})
export class StyledComponentsModule {}
