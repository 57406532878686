// tslint:disable: member-ordering
import { Injectable, OnDestroy } from '@angular/core';
import { notNull } from '@app/@shared/util-functions';
import { combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { CompanyService } from './company.service';
import { CustomerService } from './customer.service';
import { DiscountService } from './discount.service';
import { Logger } from './logger.service';
import { OrderService } from './order.service';
import { SuggestionsService } from './suggestions.service';
import { AuthService } from './auth.service';
import { EventService } from './event.service';
import { EventTypeEnum } from '../models/events/EventTypeEnum';
import { SessionService } from './session.service';

const logger = new Logger('MaestroService');
@Injectable({
  providedIn: 'root',
})
export class MaestroService implements OnDestroy {
  private constructor(
    private companyService: CompanyService,
    private orderService: OrderService,
    private customerService: CustomerService,
    private discountService: DiscountService,
    private authService: AuthService,
    private suggestionsService: SuggestionsService,
    private router: Router,
    private traceEventService: EventService,
    private sessionService: SessionService
  ) {}
  private currentMenu$?: Subscription = undefined;
  private currentStore$?: Subscription = undefined;
  private customer$?: Subscription = undefined;
  private customerOrder$?: Subscription = undefined;
  private subs: Subscription = new Subscription();

  init() {
    logger.info('Init');
    this.authService.init();
    this.initCompanyService();
  }

  private initCompanyService() {
    this.companyService.init();
    this.subs.add(
      this.companyService.currentCompany$.subscribe((company) => {
        if (company && company.subdomain) {
          logger.info('Current Company changed, fetching theme and stores');
          if (company.groupId) {
            this.companyService.getCompanyGroup();
          }
          this.traceEventService.SaveEvent(
            company?.company_id,
            EventTypeEnum.SESSION_START,
            'A new session has started'
          );
          return this.companyService.getStoreListAndListenToChange(company);
        } else if (this.companyService.companyList !== null) {
          logger.info('Current Company changed, going back to company picker');
          this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        }
      })
    );
    if (!this.currentMenu$) {
      this.currentMenu$ = this.companyService.currentMenu$
        .pipe(
          skip(1),
          distinctUntilChanged((x, y) => x?.storeId === y?.storeId)
        )
        .subscribe((menu) => {
          if (menu) {
            logger.info('Menu changed, initializing Order Service');
            this.initOrderService();
          } else {
            logger.info('Menu changed, closing Order');
            this.orderService.closeCurrentOrder();
          }
        });
    }
    if (!this.currentStore$) {
      this.currentStore$ = this.companyService.currentStore$.pipe(skip(1)).subscribe((store) => {
        if (store) {
          logger.info('Store changed, initializing Discount Service');
          this.discountService.init();
        }
      });
    }
  }

  private initOrderService() {
    // Keep if failed to get token
    this.subs.add(
      this.authService.token$.subscribe((token) => {
        if (token) {
          logger.info('Authenticated, initializing Customer Service');
          if (!this.authService.authenticatedAsGuest) {
            this.initCustomerService();
          }
          this.orderService.init({ authenticated: true });
        } else {
          this.orderService.init({ authenticated: false });
        }
        logger.info('Initializing Suggestions Service');
        this.suggestionsService.init();
      })
    );
  }

  private initCustomerService() {
    this.customerService.init();
    if (!this.customer$) {
      this.customer$ = this.customerService.customer$.pipe(filter(notNull)).subscribe((customer) => {
        logger.info('Customer changed, fetching order list');
        if (this.orderService.currentOrder && this.orderService.currentOrder.customer?.Id !== customer.Id) {
          this.orderService.setCurrentOrderCustomer(customer);
        }
        if (this.orderService?.currentOrder?.customer?.Id === customer.Id) {
          this.orderService.getOrderList();
        }
      });
    }
    if (!this.customerOrder$) {
      this.customerOrder$ = combineLatest([
        this.customerService.customer$.pipe(filter(notNull)),
        this.orderService.currentOrder$.pipe(filter(notNull)),
      ]).subscribe(([customer, order]) => {
        if (!order.customer) {
          logger.info('Customer or Order changed, setting order customer');
          this.orderService.setCurrentOrderCustomer(customer);
        }
      });
    }
  }

  public ngOnDestroy() {
    logger.info('Destroy');
    this.currentMenu$?.unsubscribe();
    this.customer$?.unsubscribe();
    this.customerOrder$?.unsubscribe();
    this.currentStore$?.unsubscribe();
    this.subs.unsubscribe();
  }
}

export interface ControledByMaestro<T = void> {
  init(options?: T): void;
}
