import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';

import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { ShellModule } from './components/shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CookiesPopupModule } from './components/cookies-popup/cookies-popup.module';
import { environment } from '@env/environment';
import * as ArgusAngular from 'src/argus/angular';
import * as Argus from 'src/argus/argus';
import { AuthInterceptor } from './@core/http/auth.interceptor';
import { EventService } from './@core/services/event.service';

Argus.init({
  withErrorTracking: true,
  withUser: true,
});

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    CoreModule,
    SharedModule,
    ShellModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(maskConfigFunction),
    CookiesPopupModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: function () {
        if (environment.production) {
          return new ArgusAngular.ArgusErrorHandler();
        }
        return new ErrorHandler();
      },
    },
    EventService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
