import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CookiesPopupComponent } from './cookies-popup.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [CookiesPopupComponent],
  exports: [CookiesPopupComponent],
})
export class CookiesPopupModule {}
