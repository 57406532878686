import { defaultTabConfig, tabC11nMetadata } from '@app/@core/theme/model/tab.metadata';
import { ThemeConfig } from '@app/@core/theme/model/theme-config';
import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';

export interface StorePickerThemeConfig {
  storePicker: {
    template: 'map-left' | 'map-right' | 'without-map';
    tab: ThemeConfig['tab'];
    backgroundImage?: string;
    mapLeft?: {
      leftContainerBGColor: string;
      rightContainerBGColor: string;
    };
    storeIcon?: string;
  };
}
export const defaultStorePickerThemeConfig: StorePickerThemeConfig = {
  storePicker: {
    template: 'map-right',
    tab: {
      ...defaultTabConfig,
      cssActive: `
        border-radius: 0px;
      `,
      cssInactive: `
        margin: 2px !important;
      `,
    },
  },
};

export const storePickerC11nMetadata: CustomizableGroup = {
  title: 'Store Picker',
  name: 'storePicker',
  items: [
    {
      title: 'Template',
      name: 'template',
      type: CustomizableItemType.SELECT,
      options: [
        {
          label: 'Map Left',
          value: 'map-left',
        },
        {
          label: 'Map Right',
          value: 'map-right',
        },
        {
          label: 'Without Map',
          value: 'without-map',
        },
      ],
    },
    {
      type: CustomizableItemType.GROUP,
      ...tabC11nMetadata,
    },
    {
      title: 'Background Image (only displayed on the without-map template)',
      name: 'backgroundImage',
      type: CustomizableItemType.IMAGE,
    },
  ],
};

// TODO save and fetch on the backend the syled map property for each company.
// the easiest way to "make" a styled map is using https://mapstyle.withgoogle.com/
// export const styledMapType = new google.maps.StyledMapType(
//   [
//     { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
//     { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
//     { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
//     {
//       featureType: 'administrative',
//       elementType: 'geometry.stroke',
//       stylers: [{ color: '#c9b2a6' }],
//     },
//     {
//       featureType: 'administrative.land_parcel',
//       elementType: 'geometry.stroke',
//       stylers: [{ color: '#dcd2be' }],
//     },
//     {
//       featureType: 'administrative.land_parcel',
//       elementType: 'labels.text.fill',
//       stylers: [{ color: '#ae9e90' }],
//     },
//     {
//       featureType: 'landscape.natural',
//       elementType: 'geometry',
//       stylers: [{ color: '#dfd2ae' }],
//     },
//     {
//       featureType: 'poi',
//       elementType: 'geometry',
//       stylers: [{ color: '#dfd2ae' }],
//     },
//     {
//       featureType: 'poi',
//       elementType: 'labels.text.fill',
//       stylers: [{ color: '#93817c' }],
//     },
//     {
//       featureType: 'poi.park',
//       elementType: 'geometry.fill',
//       stylers: [{ color: '#a5b076' }],
//     },
//     {
//       featureType: 'poi.park',
//       elementType: 'labels.text.fill',
//       stylers: [{ color: '#447530' }],
//     },
//     {
//       featureType: 'road',
//       elementType: 'geometry',
//       stylers: [{ color: '#f5f1e6' }],
//     },
//     {
//       featureType: 'road.arterial',
//       elementType: 'geometry',
//       stylers: [{ color: '#fdfcf8' }],
//     },
//     {
//       featureType: 'road.highway',
//       elementType: 'geometry',
//       stylers: [{ color: '#f8c967' }],
//     },
//     {
//       featureType: 'road.highway',
//       elementType: 'geometry.stroke',
//       stylers: [{ color: '#e9bc62' }],
//     },
//     {
//       featureType: 'road.highway.controlled_access',
//       elementType: 'geometry',
//       stylers: [{ color: '#e98d58' }],
//     },
//     {
//       featureType: 'road.highway.controlled_access',
//       elementType: 'geometry.stroke',
//       stylers: [{ color: '#db8555' }],
//     },
//     {
//       featureType: 'road.local',
//       elementType: 'labels.text.fill',
//       stylers: [{ color: '#806b63' }],
//     },
//     {
//       featureType: 'transit.line',
//       elementType: 'geometry',
//       stylers: [{ color: '#dfd2ae' }],
//     },
//     {
//       featureType: 'transit.line',
//       elementType: 'labels.text.fill',
//       stylers: [{ color: '#8f7d77' }],
//     },
//     {
//       featureType: 'transit.line',
//       elementType: 'labels.text.stroke',
//       stylers: [{ color: '#ebe3cd' }],
//     },
//     {
//       featureType: 'transit.station',
//       elementType: 'geometry',
//       stylers: [{ color: '#dfd2ae' }],
//     },
//     {
//       featureType: 'water',
//       elementType: 'geometry.fill',
//       stylers: [{ color: '#b9d3c2' }],
//     },
//     {
//       featureType: 'water',
//       elementType: 'labels.text.fill',
//       stylers: [{ color: '#92998d' }],
//     },
//   ],
//   { name: 'Styled Map' }
// );
