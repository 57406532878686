import { Injectable, OnDestroy } from '@angular/core';
import { notNull } from '@app/@shared/util-functions';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IItem } from '../models/item/item.model';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import { ControledByMaestro } from './maestro.service';
import { OrderService } from './order.service';

const TAGS = ['Popular', 'Featured'];

@Injectable({
  providedIn: 'root',
})
export class SuggestionsService implements OnDestroy, ControledByMaestro {
  private sub: Subscription = new Subscription();

  private relationMatrix: { [itemId: string]: { [relatedItemId: string]: number } } = {};
  private popularAndFeaturedItems: string[] = [];

  private readonly _isReady = new BehaviorSubject<boolean>(false);

  readonly isReady$ = this._isReady.asObservable();

  public get isReady() {
    return this._isReady.getValue();
  }

  constructor(
    private orderService: OrderService,
    private companyService: CompanyService,
    private authService: AuthService
  ) {}

  init() {
    if (this.authService.authenticatedAsGuest || !this.authService.isAuthenticated()) {
      this._isReady.next(true);
    }
    this.sub.add(
      this.companyService.currentMenu$.pipe(filter(notNull)).subscribe(({ items }) => {
        this.popularAndFeaturedItems = items
          .filter((item) => item.tags.some((tag) => TAGS.includes(tag.name)))
          .map((item) => item.id)
          .filter((v, idx, arr) => arr.indexOf(v) === idx);
      })
    );
    this.sub.add(
      this.orderService.orderList$.pipe(filter(notNull)).subscribe((orderList) => {
        orderList
          // Filter orders with more than one item
          .filter((order) => {
            const itemsLength = order.checks?.[0]?.Selections.length ?? 0;
            return itemsLength > 1;
          })
          // Get the items
          .map((order) => order.checks?.[0]?.Selections ?? [])
          // Get the ids
          .map((items) => items.map((item: IItem) => item.item_id))
          // Create the relation matrix
          .forEach((itemsIds) =>
            itemsIds.forEach((itemId: string, idx: number, arr: any) => {
              if (!this.relationMatrix[itemId]) {
                this.relationMatrix[itemId] = {};
              }
              for (let i = idx; i < arr.length - 1; i++) {
                const relatedId = arr[i + 1];
                if (!this.relationMatrix[relatedId]) {
                  this.relationMatrix[relatedId] = {};
                }
                if (relatedId !== itemId) {
                  const relation = this.relationMatrix[itemId][relatedId];
                  const relationBack = this.relationMatrix[relatedId][itemId];

                  if (!relation) {
                    this.relationMatrix[itemId][relatedId] = 0;
                  }
                  if (!relationBack) {
                    this.relationMatrix[relatedId][itemId] = 0;
                  }

                  this.relationMatrix[itemId][relatedId] += 1;
                  this.relationMatrix[relatedId][itemId] += 1;
                }
              }
            })
          );
        this._isReady.next(true);
      })
    );
  }

  public getSuggestionsForCart(...itemsId: string[]) {
    const suggestions = itemsId
      .map((itemId) => (this.relationMatrix[itemId] ? Object.entries(this.relationMatrix[itemId]) : []))
      .reduce((prev, curr) => {
        curr.forEach(([relationId, value]) => {
          if (!itemsId.includes(relationId)) {
            if (!prev[relationId]) {
              prev[relationId] = 0;
            }
            prev[relationId] += value;
          }
        });
        return prev;
      }, {} as { [suggestionId: string]: number });

    return Object.entries(suggestions)
      .sort(([, valueA], [, valueB]) => valueB - valueA)
      .map(([relationId]) => relationId)
      .concat(...this.popularAndFeaturedItems.filter((itemId) => !itemsId.includes(itemId)))
      .filter((v, idx, arr) => arr.indexOf(v) === idx);
  }

  public getPopularAndFeaturedItems() {
    return this.popularAndFeaturedItems;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
