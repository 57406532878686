import { Injectable } from '@angular/core';
import { TemplateFactory } from '@app/@core/theme/model/multiple-template.factory';
import { ThemeConfig } from '@app/@core/theme/model/theme-config';
import { DefaultHeaderComponent } from './default/default-header.component';
import { OloHeaderComponent } from './olo/olo-header.component';

@Injectable()
export class HeaderTemplateFactory implements TemplateFactory {
  getComponent(theme: ThemeConfig) {
    switch (theme.header?.template ?? 'default') {
      case 'olo':
        return OloHeaderComponent;
      default:
        return DefaultHeaderComponent;
    }
  }
}
