<div class="Header" [ngClass]="{ 'd-none': hideHeader }">
  <app-suspense [isLoading$]="isLoading$" withSkeleton="true">
    <ng-container skeleton>
      <div class="skeleton d-flex align-items-center justify-content-between">
        <div class="skeleton-logo skeleton-loader"></div>
        <div class="skeleton-desc skeleton-loader"></div>
        <div class="skeleton-buttons d-flex">
          <div class="skeleton-button skeleton-loader"></div>
          <div class="skeleton-button skeleton-loader"></div>
        </div>
      </div>
    </ng-container>
    <ng-container content>
      <app-header></app-header>
    </ng-container>
  </app-suspense>
</div>
<div class="Content">
  <router-outlet></router-outlet>
  <router-outlet name="modal"></router-outlet>
</div>
