export interface OrderStatusThemeConfig {
  orderStatus: {
    template: 'order-status-simple';
  };
}
export const defaultOrderStatusThemeConfig: OrderStatusThemeConfig = {
  orderStatus: {
    template: 'order-status-simple',
  },
};
