export interface InitOptions {
  withErrorTracking?: boolean;
  withRequestTracking?: boolean;
  withUser?: boolean | Contexts;
  withContext?: Contexts;
}

export enum EventType {
  exception = 'exception',
  warning = 'warning',
  analytics = 'analytics',
}

export interface UserDevice {
  user_agent: string;
  window_width: number;
  window_height: number;
}

export interface UserLocation {
  latitude: number;
  longitude: number;
  country: string;
  region: string;
  postal: string;
  city: string;
}

export type Context = Record<string, any>;
export type Contexts = Record<string, Context>;
export interface User {
  host: string;
  path: string;
  device: UserDevice;
  location: UserLocation;
  referring_url: string;
  ip_address: string;
  extended?: Context;
}

export interface Event {
  // Used to create tracing
  session_id: string;
  event_id: string;
  message: string;
  timestamp: number;
  start_timestamp?: number;
  type: EventType;
  user?: User;
  contexts?: Contexts;
}

export type Stacktrace = string;

export interface ExceptionEvent extends Event {
  type: EventType.exception;
  // Combined with the message used to create Issues
  stacktrace: Stacktrace;
}

export function isExceptionEvent(event: Event): event is ExceptionEvent {
  return event.type === EventType.exception;
}

export enum AnalyticsEventType {
  PAGE_VIEW = '[Page View]',
  USER_ACTION = '[User Action]',
  HTTP_Request = '[HTTP Request]',
}

export interface AnalyticsEvent extends Event {
  type: EventType.warning;
  analytics_type: AnalyticsEventType;
}
