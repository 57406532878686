<div class="App d-flex align-items-center justify-content-center">
  <app-suspense [isLoading$]="openingViewOrder$" [loadingMessage]="'Opening your order' | translate">
    <ng-container content>
      <app-suspense [isLoading$]="fillingReorder$" [loadingMessage]="'Filling your order' | translate">
        <ng-container content>
          <app-suspense [isLoading$]="loadingSomething$" [loadingMessage]="'Loading' | translate">
            <ng-container content>
              <router-outlet></router-outlet>
              <router-outlet name="modal"></router-outlet>
              <cookies-popup></cookies-popup>
              <div class="App-ThemePreview" *ngIf="themeService.previewing">
                <h5 class="mb-0">Previewing theme</h5>
                <div class="mt-2 mt-sm-0 d-flex flex-wrap">
                  <button class="btn btn-sm btn-outline-dark" (click)="backToCustomize()">
                    <i class="fa fa-chevron-left"></i>
                    Back to customization
                  </button>
                  <button class="btn btn-sm btn-success" (click)="applyTheme()">
                    <i class="fa fa-check-circle"></i>
                    Apply theme
                  </button>
                  <button
                    class="w-100 mt-2 btn btn-sm btn-outline-success"
                    *ngIf="authService.isAdmin()"
                    (click)="applyTheme()"
                  >
                    <i class="fa fa-cloud"></i>
                    Save theme for everyone
                  </button>
                </div>
              </div>
            </ng-container>
          </app-suspense>
        </ng-container>
      </app-suspense>
    </ng-container>
  </app-suspense>
</div>
