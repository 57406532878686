<ng-container *ngIf="companyService.currentCompany$ | async">
  <ng-container *ngIf="companyService.currentStore$ | async as currentStore">
    <div class="OloHeader">
      <div class="OloHeader-left">
        <ng-container *ngIf="isAuthenticated$ | async as isAuthenticated">
          <div class="btn-group" dropdown *ngIf="isAuthenticated === 'AUTHENTICATED'">
            <button
              class="btn btn-sm btn-light dropdown-toggle"
              id="account-button"
              type="button"
              dropdownToggle
              aria-label="account dropdown"
            >
              <i class="fa fa-bars" aria-hidden="true"></i>
            </button>
            <ul
              class="dropdown-menu"
              id="account-dropdown"
              role="menu"
              aria-labelledby="account-button"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-left"
            >
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openAccount()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-user mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Account' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openHistory()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-th-list mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Order History' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openFavorites()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-heart" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Favorites' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span
                  class="dropdown-item"
                  (click)="openAddresses()"
                  style="cursor: pointer"
                  [class]="{ disabled: (customerService.customer$ | async) === null }"
                >
                  <i class="fas fa-map-marker-alt mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Addresses' | translate }}</span>
                </span>
              </li>
              <li role="menuitem" *ngIf="isAdmin$ | async">
                <a class="dropdown-item" [routerLink]="'/customize'" style="cursor: pointer">
                  <i class="fas fa-pencil-ruler mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Customize' | translate }}</span>
                </a>
              </li>
              <li role="menuitem">
                <span class="dropdown-item" (click)="orderService.resetOrder()" style="cursor: pointer">
                  <i class="fas fa-sync mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Clear and reset order' | translate }}</span>
                </span>
              </li>
              <li role="menuitem">
                <span class="dropdown-item" (click)="logout()" style="cursor: pointer">
                  <i class="fas fa-sign-out-alt mr-2" aria-hidden="true"></i>
                  <span>{{ 'SHELL.' + 'Logout' | translate }}</span>
                </span>
              </li>
            </ul>
          </div>
          <button
            id="backIcon"
            style="cursor: pointer"
            class="OloHeader-left-back-icon"
            (click)="verifyRouteAndGoBack()"
            title="Back"
            role="button"
            aria-label="back to store picker"
          >
            <i class="fas fa-angle-left" aria-hidden="true"></i>
          </button>
          <button
            class="btn btn-sm btn-light"
            *ngIf="isAuthenticated === 'UNAUTHENTICATED' || isAuthenticated === 'AUTHENTICATING'"
            (click)="login()"
            [disabled]="isAuthenticated === 'AUTHENTICATING'"
            aria-label="login button"
          >
            <ng-container *ngIf="isAuthenticated === 'UNAUTHENTICATED'">
              <i class="fa fa-sign-in-alt mr-2" aria-hidden="true"></i>
              <span class="login-text">{{ 'SHELL.' + 'Login' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="isAuthenticated === 'AUTHENTICATING'">
              <app-loader isLoading="true"></app-loader>
            </ng-container>
          </button>
        </ng-container>
      </div>
      <div class="OloHeader-center">
        <div class="OloHeader-logo" [ngStyle]="{ 'background-image': 'url(' + getLogo() + ')' }"></div>
      </div>
      <div class="OloHeader-right">
        <ng-container>
          <button
            class="btn btn-sm btn-light"
            (click)="goToCart()"
            [disabled]="currentOrder === null"
            *ngIf="(currentOrder !== null && currentOrder.items.length > 0) || synchronizingOrder === true"
            [attr.aria-label]="'cart button, total items on cart' + currentOrder?.items?.length"
          >
            <i class="fas fa-shopping-cart" aria-hidden="true"></i>
            <span class="badge badge-light">
              {{ currentOrder ? (currentOrderLength$ | async) : '...' }}
            </span>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
