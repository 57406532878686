import * as moment from 'moment';

// @TODO Change references to this function to use this file, this avoid circular dependencies
export function fromUTC(offset: number, utcDate: string | moment.Moment, format: string | null = null) {
  let originalDate: moment.Moment;

  if (format) {
    originalDate = moment.utc(utcDate, format);
  } else {
    originalDate = moment.utc(utcDate);
  }

  if (originalDate.isValid()) {
    if (offset) {
      return originalDate.add(offset, 'minutes');
    } else {
      return originalDate;
    }
  }

  throw 'Invalid Date';
}

export function toUTC(offset: number, originalDate: string | moment.Moment, format: string | null = null) {
  let utcDate: moment.Moment;

  if (format) {
    utcDate = moment.utc(originalDate, format);
  } else {
    utcDate = moment.utc(originalDate);
  }

  if (utcDate.isValid()) {
    if (offset) {
      return utcDate.subtract(offset, 'minutes');
    } else {
      return utcDate;
    }
  }

  throw 'Invalid Date';
}
