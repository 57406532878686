import { CustomizableGroup, CustomizableItemType } from '@app/screens/customize/customize.model';

export interface ItemDrawerThemeConfig {
  itemDrawer: {
    template: 'box-picker' | 'input-picker';
  };
}
export const defaultItemDrawerThemeConfig: ItemDrawerThemeConfig = {
  itemDrawer: {
    template: 'box-picker',
  },
};

export const itemDrawerC11nMetadata: CustomizableGroup = {
  title: 'Customize Item',
  name: 'itemDrawer',
  items: [
    {
      title: 'Template',
      name: 'template',
      type: CustomizableItemType.SELECT,
      options: [
        {
          label: 'Box Picker',
          value: 'box-picker',
        },
        {
          label: 'Input Picker',
          value: 'input-picker',
        },
      ],
    },
  ],
};
