import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../services/logger.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { notNull } from '@app/@shared/util-functions';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(request).pipe(
      catchError((error) =>
        this.errorHandler(error, (apiCacheError) => {
          // Store/CompanyLookup and Store/{subdomain} use POST instead of GET
          let method = request.method;
          let newUrl = request.url;
          if (apiCacheError) {
            const backends = environment.backends;
            method = request.url.includes('Store/') ? 'POST' : 'GET';
            backends.map((b) => {
              if (request.url.includes(b.cacheUrl)) {
                newUrl = request.url.replace(b.cacheUrl, b.url);
              }
            });
          }
          const newRequest = new HttpRequest(method, newUrl, method === 'POST' ? request.body : undefined);
          return next.handle(newRequest as HttpRequest<any>);
        })
      )
    );
  }

  // Customize the default error handler here if needed
  private errorHandler(
    err: HttpEvent<any>,
    cb: (apiCacheError?: boolean) => Observable<HttpEvent<any>>
  ): Observable<HttpEvent<any>> {
    if (err instanceof HttpErrorResponse) {
      if ((err as HttpErrorResponse).status === 401) {
        if (err.url?.includes('customization')) {
          this.router.navigate(['admin-login']);
        } else {
          this.authService.openLogin();
          return this.authService.token$.pipe(
            filter(notNull),
            switchMap(() => {
              return cb();
            })
          );
        }
      }
      if ((err as HttpErrorResponse).url?.includes('apiCache')) {
        return cb(true);
      }
    }
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', err);
    }
    throw err;
  }
}
