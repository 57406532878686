import { IAddress } from '../address.model';

export interface ICustomer {
  Addresses: Array<IAddress> | null;
  Email: string;
  FirstName: string;
  Id: string;
  LastName: string | null;
  Name: string | null;
  Phone: string;
  Type: string;
  ExtendedData: {
    [key: string]: any;
  };
  Preferences?: {
    [key: string]: boolean;
  };
}

export class Customer implements ICustomer {
  public Addresses: Array<IAddress> | null;
  public Email: string;
  public FirstName: string;
  public Id: string;
  public LastName: string | null;
  public Name: string | null;
  public Phone: string;
  public Type: string;
  public ExtendedData: {
    [key: string]: any;
  };
  public Preferences?: {
    [key: string]: boolean;
  };

  constructor(obj: ICustomer) {
    Object.entries(obj).forEach(([key, value]) => Object.assign(this, { [key]: value }));

    if (this.Name) {
      if (!this.FirstName) {
        this.FirstName = this.Name.split(' ')?.[0];
      }

      if (!this.LastName) {
        const split = this.Name.split(' ');
        if (split.length >= 2) {
          this.LastName = split[split.length - 1];
        }
      }
    } else {
      this.Name = '';
      if (this.FirstName) {
        this.Name = this.FirstName + (this.LastName ? ' ' : '');
      }
      if (this.LastName) {
        this.Name += this.LastName;
      }
    }
  }

  public isEqual(compare: ICustomer) {
    const { FirstName, LastName, Name, Email, Phone } = compare;
    return (
      this.FirstName === FirstName &&
      this.LastName === LastName &&
      this.Name === Name &&
      this.Email === Email &&
      this.Phone === Phone
    );
  }
}
