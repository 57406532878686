import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
  declarations: [],
  imports: [CommonModule, PaginationModule, BsDropdownModule.forRoot(), AccordionModule.forRoot()],
  exports: [PaginationModule, BsDropdownModule],
})
export class NgxBootstrapModule {}
